import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import NavBar from "../../../../components/navbars/signedOutNavbar";
import Footer from "../../../../components/footer";
import ROUTES from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { createTeacher } from "../newTeacher/action";
import {
  backgroundImage,
  getFullName,
  getImageData,
} from "../../../../utils/common";
import BorderImageInput from "../../../../components/common/borderImageInput";
import InformationAccordion from "../../../../components/common/informationAccordion";
import BorderedTextInput from "../../../../components/common/borderedTextInput";
import UploadIcon from "@mui/icons-material/Upload";

export default function TeacherAuthentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.setUserInfo.payload);
  const teacherPInfo = useSelector(
    (state) => state.setTeacherPersonalInfo.payload
  );
  const uploadInfo = useSelector((state) => state.uploadImage);
  const accountInfo = useSelector((state) => state.setAccountType.payload);
  const apiRegInfo = useSelector((state) => state.createTeacher.message);
  const nationalIdImageData = getImageData(
    uploadInfo.payload,
    "nationalIdNumber"
  );
  const tscIdImageData = getImageData(uploadInfo.payload, "tscNumber");

  const backRouteChange = () => {
    let path = ROUTES.teacherSignUpSelectSchool;
    navigate(path);
  };

  const registerTeacher = (e) => {
    e.preventDefault();
    const details = {
      ...teacherPInfo,
      institutionId: teacherPInfo.institutionId.id,
      curriculaLevelsIds:
        teacherPInfo.curricula_levels.length > 0 &&
        teacherPInfo.curricula_levels.map((item) => item.value),
    };
    dispatch(createTeacher(details));
  };

  return apiRegInfo[0] &&
    apiRegInfo[0] === "Teacher registered successfully" ? (
    <Navigate to={ROUTES.userSpaceLibrary} />
  ) : (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                
                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Authentication</h6>
                  </div>
                  <div className="flex flex-col gap-6">
                    <form className="flex flex-col gap-6">
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Name"}
                          value={getFullName(
                            userInfo.firstName,
                            userInfo.otherNames
                          )}
                          disabled={true}
                        />
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Username"}
                          value={userInfo.username}
                          disabled={true}
                        />
                      </div>
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Email Address"}
                          value={userInfo.email}
                          disabled={true}
                        />
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Account Type"}
                          value={accountInfo}
                          disabled={true}
                        />
                      </div>
                      <BorderedTextInput
                        type={"text"}
                        borderText={"School"}
                        value={
                          teacherPInfo.institutionId &&
                          teacherPInfo.institutionId.name
                        }
                        disabled={true}
                      />
                      <div className="">
                        <span className="block">
                          <div
                            className={`borders flex flex-col min-h-fit max-h-40 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 borders rounded relative`}
                          >
                            <div className="relative">
                              <span
                                className={` absolute py-0 px-1 -top-3 inline-flex ml-2 surface text-xs md:text-sm `}
                              >
                                Curriculum
                              </span>
                            </div>

                            <div className="overflow-y-auto my-2 pl-3">
                              {teacherPInfo.curricula_levels && (
                                <ol className="list-decimal pl-4 my-2">
                                  {teacherPInfo.curricula_levels.length > 0 &&
                                    teacherPInfo.curricula_levels.map(
                                      (level) => (
                                        <li
                                          className="font-medium text-sm"
                                          key={level.value}
                                        >
                                          {level.label}
                                        </li>
                                      )
                                    )}
                                </ol>
                              )}
                            </div>
                          </div>
                        </span>
                      </div>
                    </form>
                    <div className="flex justify-between">
                      <button
                        className="outline-pill-button primary-light-button px-9"
                        onClick={backRouteChange}
                      >
                        Back
                      </button>
                      <button
                        className="pill-button primary-button px-9"
                        onClick={(e) => registerTeacher(e)}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
