import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  UPLOAD_WORKSHEET_LOADING,
  UPLOAD_WORKSHEET_FAILURE,
  UPLOAD_WORKSHEET_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const uploadWorksheetLoading = () => ({
  type: UPLOAD_WORKSHEET_LOADING
});

export const uploadWorksheetSuccess = payload => ({
  type: UPLOAD_WORKSHEET_SUCCESS,
  payload
});

export const uploadWorksheetFailure = error => ({
  type: UPLOAD_WORKSHEET_FAILURE,
  error
});

export const uploadWorksheetAction = (files, libraryId, courseMaterial, isAnswer = false) => async dispatch => {
  dispatch(uploadWorksheetLoading());
  const file = files[0];
  if (file && file.type !== "application/pdf") {
    console.log("Only PDF files are allowed.");
    return;
  }
  const data = new FormData();
  data.append('file', file)

  await axios.post(`${CLIENT_API_URL}/files/library?libraryId=${libraryId}&fileCategory=${courseMaterial}`, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      dispatch(uploadWorksheetSuccess({
        fileName: res.data.data.fileName,
        assetUrl: res.data.data.fileUrl,
        message: 'Worksheet uploaded successfully',
        worksheetType: isAnswer ? 'answerSheet' : 'worksheet',
      }));
    })
    .catch(error => {
      dispatch(uploadWorksheetFailure(error));
    })
}