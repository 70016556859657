import React, { useEffect, useState, useRef } from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import {
  findUserRole,
  getFullName,
  getImageData,
  getSpecificItemById,
  isObjEmpty,
} from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConsolidatedLibraryCard from "../../../components/common/consolidatedLibraryCard";
import PersonalLibraryHeader from "../../../components/common/personalLibraryHeader";
import WorkSpaceItem from "../../../components/common/workSpaceItem";
import Drafts from "./Drafts";
import Create from "./Create";
import Review from "./Review";
import Published from "./Published";
import Reported from "./Reported";
import { libraryRatingAction } from "../../../commonData/libraryRating/action";
import { getCurriculumSubjectsAction } from "../personalLibrary/action";
import { getUncategorizedWorksheetsAction } from "../../../commonData/uncategorizedWorksheets/action";

export default function MyWorkSpace() {
  const uploadInfo = useSelector((state) => state.uploadImage);
  const userDetails = useSelector((state) => state.getUserDetails.payload);
  const curriculumSubjects = useSelector(
    (state) => state.getCurriculumSubjects.payload.data
  );
  const libraries = useSelector((state) => state.getLibraries.payload.data);
  const uncategorizedWorksheets = useSelector(
    (state) => state.getUncategorizedWorksheets.payload.data
  );
  const profileImageData = getImageData(uploadInfo.payload, "profile");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const params = useParams();

  const [currentContent, setCurrentContent] = useState("create");
  const [searchTerm, setSearchTerm] = useState("");
  const [specificLibrary, setSpecificLibrary] = useState({});
  const [rating, setRating] = useState(0);

  const handleNavigation = (content) => {
    setCurrentContent(content);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const draftsCount = uncategorizedWorksheets?.length || 0;

  useEffect(() => {
    if (!isObjEmpty(userDetails)) {
      let role = findUserRole(userDetails.roles);
      // dispatch(getCurriculumSubjectsAction(specificLibrary.curriculumLevel.id, role))
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  const sidebarRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      window.innerWidth < 1280
    ) {
      setMenuOpen(false);
    }
  };

  const handleRating = (index) => {
    setRating(index);
    const ratingDetails = {
      libraryId: specificLibrary.id,
      rating: index,
      comment: `Rating ${index}`,
    };
    (async () => dispatch(libraryRatingAction(ratingDetails)))();
  };

  useEffect(() => {
    if (!isObjEmpty(specificLibrary)) {
      setRating(specificLibrary?.averageRating || 0);
    }
  }, [specificLibrary]);

  useEffect(() => {
    if (params.libraryId) {
      (async () =>
        dispatch(getUncategorizedWorksheetsAction(params.libraryId)))();
    }
  }, [dispatch, params.libraryId]);

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar title={"My Workspace"} label={"libraries"} />
      <div className="mt-0 mx-0 lg:ml-20 min-h-full">
        <PersonalLibraryHeader
          name={getFullName(userDetails.firstName, userDetails.lastName)}
          username={userDetails.username}
          location={"Nairobi, KE"}
          page={"timeline"}
          noOfFollowers={specificLibrary.numberOfFollowers || 0}
          curriculumLevel={specificLibrary?.curriculumLevel?.level}
          noOfUploads={specificLibrary?.worksheetCount}
          handleRating={handleRating}
          rating={rating}
          libraryType={specificLibrary?.libraryType?.toLowerCase()}
          libraryUser={specificLibrary?.user?.externalId}
        />

        <div
          className="z-10 fixed top-20 lg:top-24 left-4 md:left-8 lg:left-28 flex items-center xl:hidden"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <button
            id="content-menu-toogle"
            className="group flex gap-4 items-center justify-center rounded-md bg-app-colors-body-bg dark:bg-app-colors-body-bg-d surface-hover h-12 px-4 "
          >
            <svg
              className="icons text-hover-active"
              width="20"
              height="19"
              viewBox="0 0 24 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66667 0H0V2.66667H2.66667V0ZM5.33333 0V2.66667H24V0H5.33333ZM8 8H0V10.6667H8V8ZM10.6667 8V10.6667H13.3333V8H10.6667ZM16 10.6667H24V8H16V10.6667ZM0 18.6667H18.6667V16.0013H0V18.6667ZM21.3333 18.6667H24V16.0013H21.3333V18.6667Z"
              />
            </svg>
            <p className="small font-semibold text-hover-active">Expand Menu</p>
          </button>
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-8xl mx-4 md:mx-8 lg:mx-10 pt-2 md:pt-4 lg:pt-6 pb-2 md:pb-4 lg:pb-6 flex flex-col gap-6 text-sm">
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 justify-between py-4">
              <p className="text-secondary-red-500 dark:text-secondary-red-500 font-medium">
                To avoid plagiarism and copyright infringement, DO NOT publish
                content that is not yours or without the owner's consent.
              </p>

              {/*TODO: delete hidden and remove the brackets [] enclosing flex display div : enable once search funtionality is introduced*/}
              <form className="hidden [flex] items-center surface pl-4 border rounded border-app-colors-border dark:border-app-colors-border-d focus:ring-app-colors-border dark:focus:ring-app-colors-border-d">
                <div className="flex w-full">
                  <div className="w-full flex">
                    <div className="flex items-center pointer-events-none">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.4886 1.78514C8.10837 -0.595048 4.24932 -0.595048 1.86913 1.78514C-0.511063 4.16533 -0.511063 8.02514 1.86913 10.4046C4.24932 12.7848 8.10913 12.7848 10.4886 10.4046C12.8687 8.02514 12.8687 4.16533 10.4886 1.78514ZM9.41122 9.32724C7.62608 11.1124 4.73084 11.1124 2.94646 9.32724C1.16132 7.54209 1.16132 4.64762 2.94646 2.86248C4.7316 1.07733 7.62684 1.07733 9.41122 2.86248C11.1964 4.64686 11.1964 7.54209 9.41122 9.32724Z"
                            fill="#A3A7C4"
                          />
                          <path
                            d="M12.0957 10.9541L13.1729 9.87616L14.2514 10.9539L13.1742 12.0318L12.0957 10.9541Z"
                            fill="#A3A7C4"
                          />
                          <path
                            d="M14.2538 14.1825C13.9559 14.4812 13.4744 14.4812 13.1765 14.1825L9.94449 10.9505L9.52773 10.5246L9.83021 10.2222L11.6778 8.37454L11.0218 7.71777L9.94449 8.79511L8.8664 9.8732L7.78906 10.9505L8.8664 12.0279L12.0992 15.2599C12.9906 16.1513 14.4405 16.1513 15.3312 15.2599C16.2226 14.3692 16.2226 12.9193 15.3312 12.0279L14.2538 13.1052C14.5517 13.4031 14.551 13.8862 14.2538 14.1825Z"
                            fill="#A3A7C4"
                          />
                        </g>
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search content  ..."
                      name="search"
                      className="w-full surface py-4 text-sm border-0 focus:ring-0 text-gray-900 dark:text-white dark:placeholder-gray-400"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="primary-button px-4 text-white focus:ring-0 focus:outline-none font-medium rounded text-sm"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>

            <div className="flex w-full xl:gap-6">
              {/* Side Navigation */}
              <div>
                <div className="xl:sticky xl:top-24">
                  <div
                    ref={sidebarRef}
                    className={`content-menu rounded-md ${
                      menuOpen || window.innerWidth >= 1280 ? "block" : "hidden"
                    } xl:flex xl:flex-col surface  ${
                      menuOpen && window.innerWidth < 1280
                        ? " bg-app-colors-body-bg dark:bg-app-colors-body-bg-d md:w-80 lg:w-96 fixed top-20 lg:top-24 left-4 md:left-8 lg:left-28 z-20 flex flex-col w-72"
                        : ""
                    }`}
                  >
                    <div className="relative h-full">
                      <div className="bg-app-colors-body-bg dark:bg-app-colors-body-bg-d xl:bg-app-colors-surface xl:dark:bg-app-colors-surface-d rounded-t-md w-72 md:w-80 lg:w-96 sticky pt-12 pb-6 lg:pt-14 lg:pb-8 px-4 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
                        <h6 className="text-xs md:text-sm lg:text-base text-app-colors-p-small dark:text-app-colors-p-small-d">
                          My Workspace
                        </h6>
                      </div>
                      <div
                        className="relative pb-4 h-fill overflow-y-auto"
                        style={{ maxHeight: "60vh" }}
                      >
                        <div
                          className={`text-hover cursor-pointer surface-hover flex justify-between gap-6 items-center px-8 py-4 border-b border-app-colors-border dark:border-app-colors-border-d  ${currentContent === "create" ? " text-app-colors-heading dark:text-app-colors-heading-d bg-app-colors-border dark:bg-app-colors-border-d" : "text-app-colors-heading dark:text-app-colors-heading-d"}`}
                          onClick={() => handleNavigation("create")}
                        >
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide line-clamp-1">
                            Create
                          </span>
                        </div>
                        <div
                          className={`text-hover cursor-pointer surface-hover flex justify-between gap-6 items-center px-8 py-4 border-b border-app-colors-border dark:border-app-colors-border-d  ${currentContent === "drafts" ? " text-app-colors-heading dark:text-app-colors-heading-d bg-app-colors-border dark:bg-app-colors-border-d" : "text-app-colors-heading dark:text-app-colors-heading-d"}`}
                          onClick={() => handleNavigation("drafts")}
                        >
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide line-clamp-1">
                            Drafts
                          </span>
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide">
                            {draftsCount}
                          </span>
                        </div>
                        {/*TODO: delete hidden and remove the brackets [] enclosing flex display div : submitted for review*/}
                        <div
                          className={`text-hover cursor-pointer surface-hover hidden [flex] justify-between gap-6 items-center px-8 py-4 border-b border-app-colors-border dark:border-app-colors-border-d  ${currentContent === "review" ? " text-app-colors-heading dark:text-app-colors-heading-d bg-app-colors-border dark:bg-app-colors-border-d" : "text-app-colors-heading dark:text-app-colors-heading-d"}`}
                          onClick={() => handleNavigation("review")}
                        >
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide line-clamp-1">
                            Submitted for review
                          </span>
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide">
                            --
                          </span>
                        </div>
                        <div
                          className={`text-hover cursor-pointer surface-hover flex justify-between gap-6 items-center px-8 py-4 border-b border-app-colors-border dark:border-app-colors-border-d  ${currentContent === "published" ? " text-app-colors-heading dark:text-app-colors-heading-d bg-app-colors-border dark:bg-app-colors-border-d" : "text-app-colors-heading dark:text-app-colors-heading-d"}`}
                          onClick={() => handleNavigation("published")}
                        >
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide line-clamp-1">
                            Published
                          </span>
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide">
                            {specificLibrary?.worksheetCount}
                          </span>
                        </div>
                        {/*TODO: delete hidden and remove the brackets [] enclosing flex display div : reported file, to be implemented once report file has been enabled and admin dashboard completed*/}
                        <div
                          className={`text-hover cursor-pointer surface-hover hidden [flex] justify-between gap-6 items-center px-8 py-4 border-b border-app-colors-border dark:border-app-colors-border-d  ${currentContent === "reported" ? " text-app-colors-heading dark:text-app-colors-heading-d bg-app-colors-border dark:bg-app-colors-border-d" : "text-app-colors-heading dark:text-app-colors-heading-d"}`}
                          onClick={() => handleNavigation("reported")}
                        >
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide line-clamp-1">
                            Reported
                          </span>
                          <span className="text-xs md:text-sm lg:text-base font-semibold leading-relaxed tracking-wide">
                            --
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Content */}
              <div className="w-full">
                {currentContent === "create" && <Create />}
                {currentContent === "drafts" && (
                  <Drafts
                    noOfWorksheets={
                      (uncategorizedWorksheets &&
                        uncategorizedWorksheets?.length) ||
                      0
                    }
                  />
                )}
                {currentContent === "review" && <Review />}
                {currentContent === "published" && <Published />}
                {currentContent === "reported" && <Reported />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
