import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_COUNTY_LOADING,
  GET_COUNTY_FAILURE,
  GET_COUNTY_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getCountiesLoading = () => ({
  type: GET_COUNTY_LOADING
});

export const getCountiesSuccess = payload => ({
  type: GET_COUNTY_SUCCESS,
  payload
});

export const getCountiesFailure = error => ({
  type: GET_COUNTY_FAILURE,
  error
});

export const getCountiesAction = (countryId) => async dispatch => {
  dispatch(getCountiesLoading());

  axios.get(`${CLIENT_API_URL}/counties?countryId=${countryId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        dispatch(getCountiesSuccess(res.data));
      })
      .catch(error => {
        dispatch(getCountiesFailure({
          message: 'An error occurred while fetching counties'
        }))
      })
}