import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import NavBar from "../../../../components/navbars/signedOutNavbar";
import Footer from "../../../../components/footer";
import ROUTES from "../../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { createStudent } from "../newStudent/action";
import { backgroundImage, getFullName } from "../../../../utils/common";
import InformationAccordion from "../../../../components/common/informationAccordion";
import BorderedTextInput from "../../../../components/common/borderedTextInput";

export default function StudentAuthentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const studPersonalInfo = useSelector(
    (state) => state.setStudentPersonalInfo.payload
  );
  const accountInfo = useSelector((state) => state.setAccountType.payload);
  const userInfo = useSelector((state) => state.setUserInfo.payload);
  const apiRegError = useSelector((state) => state.createStudent.error);
  const apiRegInfo = useSelector((state) => state.createStudent.message);

  const handleBackNavigation = () => {
    const backRoute = ROUTES.studentSignUpSelectSchool;
    navigate(backRoute);
  };

  const registerStudent = (e) => {
    e.preventDefault();
    const details = {
      ...studPersonalInfo,
      gradeId: studPersonalInfo.gradeId.id,
      curriculumId: studPersonalInfo.curriculumId,
      curriculumLevelId: studPersonalInfo.curriculumLevelId,
      institutionId: studPersonalInfo.institutionId.id,
      parentUsername:
        studPersonalInfo.parentUsername !== undefined
          ? studPersonalInfo.parentUsername
          : null,
    };
    dispatch(createStudent(details));
  };

  return apiRegInfo && apiRegInfo === "Student registered successfully" ? (
    <Navigate to={ROUTES.userSpaceLibrary} />
  ) : (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                {apiRegError ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    <div>{apiRegError}</div>
                  </div>
                ) : null}
                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Authentication</h6>
                  </div>
                  <div className="flex flex-col gap-6">
                    <form className="flex flex-col gap-6">
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Name"}
                          value={getFullName(
                            userInfo.firstName,
                            userInfo.otherNames
                          )}
                          disabled={true}
                        />
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Username"}
                          value={userInfo.username}
                          disabled={true}
                        />
                      </div>
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Email Address"}
                          value={userInfo.email}
                          disabled={true}
                        />

                        <BorderedTextInput
                          type={"text"}
                          borderText={"Date of Birth"}
                          value={userInfo.dateOfBirth}
                          disabled={true}
                        />
                      </div>
                      <div className="flex flex-col sm:flex-row gap-6">
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Account Type"}
                          value={accountInfo}
                          disabled={true}
                        />
                        <BorderedTextInput
                          type={"text"}
                          borderText={"Grade"}
                          value={
                            studPersonalInfo.gradeId &&
                            studPersonalInfo.gradeId.name
                          }
                          disabled={true}
                        />
                      </div>
                      <BorderedTextInput
                        type={"text"}
                        borderText={"School"}
                        value={
                          studPersonalInfo.institutionId &&
                          studPersonalInfo.institutionId.name
                        }
                        disabled={true}
                      />
                    </form>
                    <div className="flex justify-between">
                      <button
                        className=" outline-pill-button primary-light-button px-9"
                        onClick={handleBackNavigation}
                      >
                        Back
                      </button>
                      <button
                        className="pill-button primary-button px-9"
                        onClick={(e) => registerStudent(e)}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </div>
  );
}
