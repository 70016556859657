import React from "react";

const AnnouncementsHeader = () => {
  return (
    <div className="mt-16 lg:mt-20 ml-0">
      <div
        className={` pt-8 flex h-full px-4 md:px-6 lg:px-8 text-center justify-center bg-primary-950 bg-cover bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/header-bg-library.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 150s linear infinite",
        }}
      >
        <div className="flex flex-col items-center max-w-8xl">
          <div className="flex flex-col items-center gap-4 max-w-5xl">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              What’s New on Ticet EDU?
            </title>
            <p className=" text-app-colors-heading-d dark:text-app-colors-heading-d mb-2">
              Stay informed with the latest updates from Ticet EDU! Our
              Announcements section keeps you in the loop with major platform
              updates, new features, enhancements, and important news. Whether
              it's an exciting upgrade to your learning experience or a
              milestone achievement for our community, you'll find it all here.
              Stay connected and never miss a moment of growth and innovation!
            </p>
          </div>
          <img
            src="/images/account-bottom.webp"
            alt=""
            className="flex w-full mt-4"
          />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsHeader;
