import {
  USER_REGISTRATION_FAILURE,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_LOADING,
  SET_USER_INFO_SUCCESS,
  SET_USER_INFO_FAILURE
} from './constants'
import axios from 'axios';
import {CLIENT_API_URL} from '../../../utils/constants';
import {setJwtTokenCookie, setRefreshTokenCookie} from '../../../utils/cookieHelper';

export const setUserPersonalInfo = payload => ({
  type: SET_USER_INFO_SUCCESS,
  payload
});

export const setUserInfoFailure = error => ({
  type: SET_USER_INFO_FAILURE,
  error
});

export const userRegistrationLoading = () => {
  return {
    type: USER_REGISTRATION_LOADING
  }
}

export const userRegistrationSuccess = message => {
  return {
    type: USER_REGISTRATION_SUCCESS,
    message
  }
}

export const userRegistrationFailure = error => {
  return {
    type: USER_REGISTRATION_FAILURE,
    error
  }
}

export const userRegistrationAction = ({ firstName, otherNames, email, username, password, userType, dateOfBirth }) => async dispatch => {
  const userDetails = {firstName, otherNames, email, username, password, userType, dateOfBirth}
  const loginDetails = {username, password}
  dispatch(userRegistrationLoading())
  await axios.post(`${CLIENT_API_URL}/users`, userDetails, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(async res => {
    if (res.status !== 201) return dispatch(userRegistrationFailure({message: `${res.data.msg}. Please check your data or login if you have already registered.`}))
    dispatch(userRegistrationSuccess(['User registered successfully']));
    await axios.post(`${CLIENT_API_URL}/users/login`, loginDetails, {
      headers: {}
    })
    .then(resp => {
      setJwtTokenCookie(resp.data.data.access_token);
      setRefreshTokenCookie(resp.data.data.refresh_token);
      dispatch(userRegistrationSuccess(['User logged in successfully']));
    })
    .catch(error => {
      dispatch(userRegistrationFailure({message: 'User login failed.'}))
    })
  })
  .catch(error => {
    dispatch(userRegistrationFailure({
      message: 'An error occurred while registering user. Please check your data or login if you have already registered.'
    }));
  })
}