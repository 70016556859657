import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_USER_LOADING,
  GET_USER_FAILURE,
  GET_USER_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getUserDetailsLoading = () => ({
  type: GET_USER_LOADING
});

export const getUserDetailsSuccess = payload => ({
  type: GET_USER_SUCCESS,
  payload
});

export const getUserDetailsFailure = error => ({
  type: GET_USER_FAILURE,
  error
});

export const getUserDetailsAction = () => async dispatch => {
  dispatch(getUserDetailsLoading());

  axios.get(`${CLIENT_API_URL}/users`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    dispatch(getUserDetailsSuccess(res.data.data));
  })
  .catch(error => {
    dispatch(getUserDetailsFailure({
      message: 'An error occurred while fetching User'
    }))
  })
}