import React, { Fragment, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import NavBar from "../../../components/navbars/signedOutNavbar";
import Footer from "../../../components/footer";
import ROUTES from "../../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { setUserPersonalInfo, userRegistrationAction } from "./action";
import InformationAccordion from "../../../components/common/informationAccordion";
import BorderedTextInput from "../../../components/common/borderedTextInput";
import { backgroundImage, validateEmail } from "../../../utils/common";
import moment from "moment";
import BorderDateInput from "../../../components/common/borderDateInput";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [formFilled, setFormFilled] = useState(true);
  const [registrationErrors, setRegistrationErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const regInfo = useSelector((state) => state.userRegistration.message);
  const regLoading = useSelector((state) => state.userRegistration.loading);
  const accountInfo = useSelector((state) => state.setAccountType.payload);
  const apiRegError = useSelector((state) => state.userRegistration.error);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const userRegistration = (e) => {
    e.preventDefault();
    if (accountInfo === "institution" && userInfo.email && userInfo.password) {
      setFormFilled(true);
    } else if (
      accountInfo !== "institution" &&
      userInfo.firstName &&
      userInfo.otherNames &&
      userInfo.username &&
      userInfo.email &&
      userInfo.password
    ) {
      setFormFilled(true);
    } else {
      return setFormFilled(false);
    }

    if (accountInfo === "student" && !userInfo.dateOfBirth)
      setFormFilled(false);

    if (userInfo.dateOfBirth && !isPersonAgeLegal(userInfo["dateOfBirth"]))
      return handleShowModal();

    if (!validateEmail(userInfo.email)) {
      setRegistrationErrors([
        ...registrationErrors,
        "Please enter correct email",
      ]);
      return;
    }

    if (
      userInfo.password &&
      checkPasswordsMatch(userInfo.password, userInfo.confirm_password)
    ) {
      let userData = {
        ...userInfo,
        firstName: accountInfo === "institution" ? null : userInfo.firstName,
        otherNames: accountInfo === "institution" ? null : userInfo.otherNames,
        dateOfBirth:
          accountInfo === "institution" ? null : userInfo.dateOfBirth,
        username: userInfo.username ? userInfo.username : userInfo.email,
        userType: accountInfo.toUpperCase(),
      };
      dispatch(userRegistrationAction(userData));
      const {
        password,
        confirm_password,
        ...setUserData // assigns remaining keys to a new `noB` object
      } = userInfo;
      dispatch(setUserPersonalInfo(setUserData));
    } else {
      setRegistrationErrors([
        ...registrationErrors,
        "Passwords do not match. Please check and try again",
      ]);
    }
  };

  const isPersonAgeLegal = (birthday) => {
    return moment().diff(birthday, "years") >= 13;
  };

  const loginRouteChange = () => {
    let path = ROUTES.home;
    navigate(path);
  };

  const AccountTypeRouteChange = () => {
    let path = ROUTES.accountType;
    navigate(path);
  };

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const checkPasswordsMatch = (password, confirm_password) => {
    return password === confirm_password;
  };

  const selectAccount = (account) => {
    switch (account) {
      case "other":
        return ROUTES.userSpaceLibrary;
      case "teacher":
        return ROUTES.teacherSignUpSelectSchool;
      case "institution":
        return ROUTES.schoolSignUpSchoolDetails;
      case "student":
        return ROUTES.studentSignUpSelectSchool;
      default:
        return;
    }
  };

  useEffect(() => {
    if (regInfo && regInfo[0] === "User logged in successfully") {
      navigate(selectAccount(accountInfo), { replace: true, state: { from: 'register' } });
    }
  }, [regInfo, navigate, accountInfo]);

  const dark =
    localStorage.getItem("color-theme") === "dark" ||
    (!("color-theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches);

  const registerErrors = [...new Set(registrationErrors)];

  return (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {showModal && (
        <div className="z-30 fixed inset-0 bg-primary-950 bg-opacity-90 w-full h-full flex items-center justify-center">
          <div className="surface w-full md:w-3/4 xl:w-1/2 rounded-lg shadow-lg p-4 py-4 md:py-6 lg:py-8 mx-8 md:px-12 lg:px-16">
            <div className="flex justify-between items-center w-full py-2 border-b border-app-colors-border dark:border-app-colors-border-d">
              <div className="flex flex-col justify-center">
                <img
                  id="logo-light"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "block" : "hidden"}`}
                  src="/images/logo-light.svg"
                  alt="Dark mode logo"
                />
                <img
                  id="logo-dark"
                  className={`h-8 md:h-8 lg:h-16 ${dark ? "hidden" : "block"}`}
                  src="/images/logo-dark.svg"
                  alt="Light mode logo"
                />
                <h5>Age Restriction</h5>
              </div>
              <svg
                onClick={handleHideModal}
                className="icons hover:text-secondary-red-500 cursor-pointer"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.94167 11.1639H11.1639V8.94167H8.94167V11.1639ZM19.4806 2.19611L17.9094 0.625L11.6239 6.91056L13.195 8.48167L19.4806 2.19611ZM0.625 17.9094L2.19611 19.4806L8.48167 13.1961L6.90944 11.625L0.625 17.9094ZM11.6239 13.1961L17.9094 19.4806L19.4806 17.9094L13.195 11.625L11.6239 13.1961ZM0.625 2.19722L6.91056 8.48167L8.48278 6.91056L2.19611 0.625L0.625 2.19722Z"
                />
              </svg>
            </div>
            <div className="overflow-y-auto py-8">
              <p>
                We're sorry, we are unable to process your registration request.
                ticet edu{" "}
                <a href="/privacy-policy">
                  Terms of Use Policy and Protection Policy
                </a>
                , require parental consent for children under the age of 13 to
                create an account. To continue with your registration, we kindly
                request that you ask your parent or legal guardian to create an
                account on your behalf (<a href="/faqs">FAQs</a>
                ).
              </p>
              <p className="mt-2">
                Thank you for your understanding and cooperation. If you have
                any further questions or concerns, please don't hesitate to
                reach out to our support team for assistance.
              </p>
              <br />
              <p className="mt-2">
                Best regards,
                <br />
                ticet edu Team.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div
              id="reg-form"
              className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center"
            >
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                {registrationErrors.length !== 0 || apiRegError ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    {apiRegError ? <div>{apiRegError.message}</div> : null}
                    {registrationErrors.length !== 0 &&
                      registerErrors.map((error, index) => (
                        <div key={index}>{error}</div>
                      ))}
                  </div>
                ) : null}
                {!formFilled ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    Please fill in the missing fields
                  </div>
                ) : null}

                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Create an account</h6>
                  </div>
                  <div>
                    <form className="flex flex-col gap-6">
                      {accountInfo && accountInfo !== "institution" ? (
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col sm:flex-row gap-6">
                            <div className="basis-1">
                              <BorderedTextInput
                                id={"firstName"}
                                name={"firstName"}
                                type={"text"}
                                borderText={`First Name ${!formFilled && !userInfo.firstName ? "*" : ""}`}
                                handleInputChange={handleChange}
                              />
                            </div>

                            <div className="basis-2">
                              <BorderedTextInput
                                id={"otherNames"}
                                name={"otherNames"}
                                type={"text"}
                                borderText={`Other Names ${!formFilled && !userInfo.otherNames ? "*" : ""}`}
                                handleInputChange={handleChange}
                              />
                            </div>
                          </div>
                          {accountInfo && accountInfo === "student" ? (
                            <div className="">
                              <BorderDateInput
                                id={"dateOfBirth"}
                                name={"dateOfBirth"}
                                borderText={`Date of Birth ${!formFilled && !userInfo.dateOfBirth ? "*" : ""}`}
                                handleInputChange={handleChange}
                                value={
                                  userInfo.dateOfBirth
                                    ? userInfo.dateOfBirth
                                    : ""
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      <div
                        className={`${accountInfo && accountInfo !== "institution" ? "flex flex-col sm:flex-row gap-6" : ""}`}
                      >
                        {accountInfo && accountInfo !== "institution" ? (
                          <div>
                            <BorderedTextInput
                              id={"username"}
                              name={"username"}
                              type={"text"}
                              borderText={`Username ${!formFilled && !userInfo.username ? "*" : ""}`}
                              handleInputChange={handleChange}
                            />
                          </div>
                        ) : null}
                        <div
                          className={`${accountInfo && accountInfo !== "institution" ? "" : ""}`}
                        >
                          <BorderedTextInput
                            id={"email"}
                            name={"email"}
                            type={"text"}
                            borderText={`Email Address ${!formFilled && !userInfo.email && !validateEmail(userInfo.email) ? "*" : ""}`}
                            handleInputChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="relative">
                        <BorderedTextInput
                          id={"password"}
                          name={"password"}
                          type={showPassword ? "text" : "password"}
                          borderText={`Password ${!formFilled && !userInfo.password ? "*" : ""}`}
                          handleInputChange={handleChange}
                        />
                        <button
                          id="show-hide"
                          type="button"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <svg
                              className="icons"
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                              <path d="M32 9.59961C14.3264 9.59961 0 31.9996 0 31.9996C0 31.9996 9.4176 46.7196 22.4 52.2908V45.1388C16.4416 41.798 11.152 36.1948 7.8656 31.9932C12.8352 25.6092 22.384 15.9996 32 15.9996C41.5616 15.9996 51.1456 25.6284 56.1344 32.0092C52.8544 36.2204 47.5744 41.8236 41.6 45.158V52.2908C54.5824 46.7196 64 31.9996 64 31.9996C64 31.9996 49.6704 9.59961 32 9.59961Z" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M32.0004 22.4004C26.698 22.4004 22.4004 26.698 22.4004 32.0004C22.4004 37.3028 26.698 41.6004 32.0004 41.6004C37.3028 41.6004 41.6004 37.3028 41.6004 32.0004C41.6004 26.698 37.3028 22.4004 32.0004 22.4004ZM35.2004 35.2004H28.8004V28.8004H35.2004V35.2004Z"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="icons"
                              width="20"
                              height="20"
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_61712_221726)">
                                <path d="M35.2008 48H28.8008V54.4H35.2008V48Z" />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M35.1996 0H41.5996L15.9996 64H9.59961L35.1996 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M33.334 41.4658C37.9996 40.813 41.5996 36.8482 41.5996 32.0002C41.5996 29.7474 40.7932 27.7026 39.494 26.0674L33.334 41.4658Z"
                                />
                                <path d="M22.4 18.8418V11.709C9.4176 17.2802 0 32.0002 0 32.0002C0 32.0002 3.6768 37.7442 9.6 43.4114V34.0962C8.9792 33.3698 8.3872 32.6594 7.8656 31.9874C11.1456 27.7794 16.4256 22.173 22.4 18.8418Z" />
                                <path d="M41.5996 11.709V18.8642C47.558 22.205 52.8476 27.805 56.134 32.0098C52.854 36.221 47.574 41.8242 41.5996 45.1586V52.2914C54.582 46.7202 63.9996 32.0002 63.9996 32.0002C63.9996 32.0002 54.582 17.2834 41.5996 11.709Z" />
                              </g>
                              <defs>
                                <clipPath id="clip0_61712_221726">
                                  <rect width="64" height="64" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </button>
                      </div>

                      <div className="relative">
                        <BorderedTextInput
                          id={"confirm_password"}
                          name={"confirm_password"}
                          type={showPassword ? "text" : "password"}
                          borderText={"Re-enter Password"}
                          handleInputChange={handleChange}
                        />
                      </div>

                      <div className="flex items-center justify-between py-2">
                        <a onClick={AccountTypeRouteChange}>
                          Change Account type
                        </a>
                        <button
                          className="pill-button primary-button ml-auto flex flex-nowrap px-8"
                          onClick={userRegistration}
                          disabled={regLoading}
                        >
                          {regLoading && (
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="w-4 h-4 mr-2 text-white animate-spin fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <span className="flex gap-2 px-4 lg:px-6">
                  <p className="text-white dark:text-white">
                    Already have an account?
                  </p>
                  <button
                    className="font-medium text-white hover:text-secondary-yellow-500 cursor-pointer"
                    onClick={loginRouteChange}
                  >
                    Sign in
                  </button>
                </span>
              </div>
            </div>
            <div id="accor" className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
