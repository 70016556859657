import {
  SET_TEACHER_PERSONALINFO_FAILURE,
  SET_TEACHER_PERSONALINFO_SUCCESS
} from './constants'

const initialState = {
  payload: [],
  error: null
}

export default function setTeacherPersonalInfo(state=initialState, action) {
  const { type, payload, error } = action

  switch(type) {
    case SET_TEACHER_PERSONALINFO_SUCCESS:
      return {
        ...state, payload
      };
    case SET_TEACHER_PERSONALINFO_FAILURE:
      return {
        ...state, error
      };
    default:
      return state;
  }
}
