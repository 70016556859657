import React from "react";

const ConsolidatedLibraryCard = ({ cardName, handleClick = () => {} }) => {
  return (
    <div
      className="group surface surface-hover rounded cursor-pointer aspect-ratio-16-9 sm:aspect-ratio-1-1"
      onClick={handleClick}
    >
      <div className="h-full rounded">
        <div
          className="py-4 bg-no-repeat bg-cover bg-center h-1/2 w-full rounded-t"
          style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
        ></div>
        <div className="flex justify-center items-center h-28 px-4">
          <h6 className="text-hover-active w-full text-base text-center line-clamp-2">
            {cardName}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ConsolidatedLibraryCard;
