import React, { useState } from "react";
import Select from "react-select";

const BorderedDropdownTag = ({
  borderText,
  id,
  name,
  value = undefined,
  handleInputChange,
  disabled = false,
  bgColor = "dark:bg-app-colors-surface-d bg-app-colors-surface",
  options = [],
}) => {
  const [focused, setFocused] = useState(false);
  const dark = localStorage.getItem("color-theme") === "dark";

  return (
    <div>
      <span className="block">
        <div
          className={`relative borders flex flex-col pt-2 text-app-colors-p-small dark:text-app-colors-p-small-d focus-within:border-primary-main-700 focus:text-primary-main-700 rounded ${borderText.includes("*") ? "border-secondary-red-500 focus-within:border-secondary-red-500" : ""}`}
        >
          <div className="relative">
            <span
              className={`absolute ml-2 ${bgColor} px-1 -top-5 text-xs md:text-sm ${focused ? "text-primary-main-700" : ""} ${borderText.includes("*") ? "text-secondary-red-500" : ""}`}
            >
              {borderText}
            </span>
          </div>

          <div>
            <Select
              defaultValue={options.length > 1 ? options[0] : null}
              isMulti
              id={id}
              name={name}
              options={options}
              className="basic-multi-select surface"
              classNamePrefix="select"
              onChange={handleInputChange}
              onFocus={() => setFocused(true)}
              styles={CustomSelectStyles}
              placeholder="Select"
              dark={dark}
              value={value}
            />
          </div>
        </div>
      </span>
    </div>
  );
};

const CustomSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.dark ? "#20232b" : "#f8fafc",
    color: state.selectProps.dark ? "#fff5f3" : "#0f0f0f",
    border: state.selectProps.dark ? "0px solid #6b7280" : "0px solid #d1d5db",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.selectProps.dark ? "#6b7280" : "#d1d5db",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.dark ? "#20232b" : "#f8fafc",
    maxHeight: "12rem",
    overflowY: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? state.selectProps.dark
        ? "#343946"
        : "#f5f5f5"
      : state.selectProps.dark
        ? "#20232b"
        : "#f8fafc",
    color: state.selectProps.dark ? "#fff5f3" : "#0f0f0f",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.dark ? "#343946" : "#e2e8f0",
    color: state.selectProps.dark ? "#fff5f3" : "#0f0f0f",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.selectProps.dark ? "#fff5f3" : "#0f0f0f",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: state.selectProps.dark ? "#fff5f3" : "#0f0f0f",
    "&:hover": {
      backgroundColor: state.selectProps.dark ? "#20232b" : "#cbd5e0",
    },
  }),
};

export default BorderedDropdownTag;
