import React, {useEffect, useState} from 'react';
import WorkSpacePublishedItem from '../../../../components/common/workSpacePublishedItem';
import {useDispatch, useSelector} from 'react-redux';
import {getSubjectsAction, getSubjectsClear} from '../../../../commonData/subjects/action';
import {useNavigate, useParams} from 'react-router-dom';
import {getSpecificItemById} from '../../../../utils/common';

const Published = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const subjects = useSelector((state) => state.getSubjects.payload.data);
  const libraries = useSelector((state) => state.getLibraries.payload.data);

  const [specificLibrary, setSpecificLibrary] = useState({});
  const curriculumLevelId = specificLibrary?.curriculumLevel?.id;

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  useEffect(() => {
    if (curriculumLevelId) {
      dispatch(getSubjectsClear());
      (async () => dispatch(getSubjectsAction(curriculumLevelId)))();
    }
  }, [dispatch, curriculumLevelId]);

  const worksheetsRoutePage = (subjectId) => {
    const path = `/user_space/${params.libraryId}/my_workspace/subjects/${subjectId}`;
    navigate(path);
  };

  return (
    <div>
      <div className="grid auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        {subjects && subjects.length > 0 ? (
          subjects.map((subject, index) => (
            <WorkSpacePublishedItem
              key={index}
              item={`${subject.name} (${subject.code})`}
              noOfItems={subject?.noOfItems || 0}
              handleClick={() => worksheetsRoutePage(subject.id)}
            />
          ))
        ) : (
          <div className="surface flex gap-2 col-span-full border-l-8 border-secondary-red-600 p-4 rounded-md">
            <svg
              width="24"
              height="24"
              viewBox="0 0 64 64"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_52244_11715)">
                <path
                  d="M32 0C14.3264 0 0 14.3168 0 31.9776C0 46.3072 9.4272 58.4288 22.4256 62.4992V55.7216C13.0464 51.92 6.4256 42.7424 6.4256 32.0032C6.4256 17.8656 17.888 6.4064 32.0256 6.4064C46.1632 6.4064 57.6256 17.8656 57.6256 32.0032C57.6256 42.7424 51.0016 51.9264 41.6256 55.7216V62.4736C54.5952 58.3936 64 46.288 64 31.9776C64 14.3168 49.6672 0 32 0ZM28.8256 48.0032H35.2256V28.8032H28.8256V48.0032ZM28.8256 22.4032H35.2256V16.0032H28.8256V22.4032ZM28.8256 64H35.2256V57.6H28.8256V64Z"
                  fill="#f8583b"
                />
              </g>
              <defs>
                <clipPath id="clip0_52244_11715">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>There are no subjects to show</p>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default Published;
