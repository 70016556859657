import axios from 'axios';
import {
  DELETE_UNCATEGORIZED_WORKSHEET_LOADING,
  DELETE_UNCATEGORIZED_WORKSHEET_FAILURE,
  DELETE_UNCATEGORIZED_WORKSHEET_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../../utils/cookieHelper';
import {CLIENT_API_URL} from '../../../utils/constants';

export const deleteUncategorizedWorksheetLoading = () => ({
  type: DELETE_UNCATEGORIZED_WORKSHEET_LOADING
});

export const deleteUncategorizedWorksheetSuccess = payload => ({
  type: DELETE_UNCATEGORIZED_WORKSHEET_SUCCESS,
  payload
});

export const deleteUncategorizedWorksheetFailure = error => ({
  type: DELETE_UNCATEGORIZED_WORKSHEET_FAILURE,
  error
});

export const deleteUncategorizedWorksheetAction = (libraryId, fileId) => async dispatch => {
  dispatch(deleteUncategorizedWorksheetLoading());
  try {
    const response = await axios.delete(`${CLIENT_API_URL}/files/library/uncategorized/${fileId}?libraryId=${libraryId}`, {
      headers: {
        'Authorization': `Bearer ${await getJwtTokenCookie()}`
      }
    });
    dispatch(deleteUncategorizedWorksheetSuccess(response.data));
  } catch (error) {
    dispatch(deleteUncategorizedWorksheetFailure({
      message: 'An error occurred while deleting the uncategorized worksheet'
    }));
  }
};