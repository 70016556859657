import axios from 'axios';

import {
  GET_CURRICULUM_SUBJECTS_LOADING,
  GET_CURRICULUM_SUBJECTS_SUCCESS,
  GET_CURRICULUM_SUBJECTS_FAILURE,
  GET_CURRICULUM_SUBJECTS_CLEAR
} from './constants';
import {CLIENT_API_URL} from '../../../utils/constants';
import {getJwtTokenCookie} from '../../../utils/cookieHelper';

export const getCurriculumSubjectsLoading = () => ({
  type: GET_CURRICULUM_SUBJECTS_LOADING
})

export const getCurriculumSubjectsSuccess = payload => ({
  type: GET_CURRICULUM_SUBJECTS_SUCCESS,
  payload
})

export const getCurriculumSubjectsFailure = error => ({
  type: GET_CURRICULUM_SUBJECTS_FAILURE,
  error
})

export const clearCurriculumSubjects = () => ({
  type: GET_CURRICULUM_SUBJECTS_CLEAR
})

export const getCurriculumSubjectsAction = (curriculumLevelId, role) => async dispatch => {
  dispatch(getCurriculumSubjectsLoading())

  let curriculumSubjectsUrl = null;
  if (role && role === 'ROLE_STUDENT') {
    curriculumSubjectsUrl = '/students/curriculum-subjects'
  } else if (role && role === 'ROLE_TEACHER') {
    curriculumSubjectsUrl = '/teachers/curriculum-subjects'
  }

  axios.get(`${CLIENT_API_URL}${curriculumSubjectsUrl}?curriculumLevelId=${curriculumLevelId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
    .then(res => {
      dispatch(getCurriculumSubjectsSuccess(res.data));
    })
    .catch(error => {
      dispatch(getCurriculumSubjectsFailure({
        message: 'An error occurred while fetching subjects'
      }))
    })
}