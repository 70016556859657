import { getJwtTokenCookie, getRefreshTokenCookie, setJwtTokenCookie } from '../cookieHelper';
import axios from '../api/axios';
import { CLIENT_API_URL } from '../constants';
import { parseJwt } from '../common';

const REFRESH_BUFFER_MS = 30 * 1000; // 30 seconds

export const authVerify = async () => {
  let token = await getJwtTokenCookie();

  if (!token) {
    return 'missing token';
  }

  const decodedToken = parseJwt(token);
  const tokenExpiryTime = decodedToken?.exp * 1000;

  // Check if token has an expiration time and if it's about to expire within the buffer period
  if (!tokenExpiryTime || tokenExpiryTime - Date.now() <= REFRESH_BUFFER_MS) {
    try {
      const response = await axios.post(
          `${CLIENT_API_URL}/users/auth/token`,
          JSON.stringify({ refreshToken: await getRefreshTokenCookie() }),
          { headers: { 'Content-Type': 'application/json' } }
      );

      // Update the token
      token = response.data.data.access_token;
      await setJwtTokenCookie(token);
      return token;
    } catch (error) {
      await setJwtTokenCookie(null); // Clear token if refresh fails
      return 'missing token';
    }
  }

  // Token is valid and not close to expiry
  return token;
};
