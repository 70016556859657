import {
  GET_CURRICULUM_SUBJECTS_LOADING,
  GET_CURRICULUM_SUBJECTS_SUCCESS,
  GET_CURRICULUM_SUBJECTS_FAILURE,
  GET_CURRICULUM_SUBJECTS_CLEAR
} from './constants';

const initState = {
  loading: false,
  payload: [],
  error: null
}

export default function getCurriculumSubjects(state=initState, action) {
  const {type, payload, error} = action;

  switch(type) {
    case GET_CURRICULUM_SUBJECTS_LOADING:
      return { ...state, loading: true };
    case GET_CURRICULUM_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_CURRICULUM_SUBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
      case GET_CURRICULUM_SUBJECTS_CLEAR:
        return {
          ...state,
          loading: false,
          payload: []
        };
    default:
      return state;
  }
}