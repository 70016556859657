import React, { useEffect, useState } from "react";
import SignedInNavBar from "../../../components/navbars/signedInNavbar";
import {
  findUserRole,
  getFullName,
  getImageData,
  getSpecificItemById,
  isObjEmpty,
} from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConsolidatedLibraryCard from "../../../components/common/consolidatedLibraryCard";
import PersonalLibraryHeader from "../../../components/common/personalLibraryHeader";
import {clearCurriculumSubjects, getCurriculumSubjectsAction} from './action';
import { libraryRatingAction } from "../../../commonData/libraryRating/action";

export default function PersonalLibrary() {
  const uploadInfo = useSelector((state) => state.uploadImage);
  const userDetails = useSelector((state) => state.getUserDetails.payload);
  const curriculumSubjects = useSelector(
    (state) => state.getCurriculumSubjects.payload.data
  );
  const libraries = useSelector((state) => state.getLibraries.payload.data);
  const profileImageData = getImageData(uploadInfo.payload, "profile");

  const navigate = useNavigate();

  const [specificLibrary, setSpecificLibrary] = useState({});
  const [rating, setRating] = useState(0);

  const dispatch = useDispatch();
  const params = useParams();

  const handleUpdateLibraryRoute = () => {
    let path = `/user_space/${params.libraryId}/my_library_space/personal_library/update_library_worksheets`;
    navigate(path);
  };

  const handleRating = (index) => {
    setRating(index);
    const ratingDetails = {
      libraryId: specificLibrary.id,
      rating: index,
      comment: `Rating ${index}`,
    };
    (async () => dispatch(libraryRatingAction(ratingDetails)))();
  };

  const handleSubjectRoute = (subjectId) => {
    const path = `/user_space/${params.libraryId}/my_workspace/subjects/${subjectId}`;
    navigate(path);
  };

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      setSpecificLibrary(getSpecificItemById(libraries, params.libraryId));
    }
  }, [libraries]);

  useEffect(() => {
    if (!isObjEmpty(userDetails) && !isObjEmpty(specificLibrary)) {
      dispatch(clearCurriculumSubjects());
      let role = findUserRole(userDetails.roles);
      (async () =>
        dispatch(
          getCurriculumSubjectsAction(specificLibrary.curriculumLevel.id, role)
        ))();
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (!isObjEmpty(specificLibrary)) {
      setRating(specificLibrary?.averageRating || 0);
    }
  }, [specificLibrary]);

  return (
    <div className="body flex flex-col min-h-screen">
      <SignedInNavBar title={"Personal Library"} label={"libraries"} />
      <div className="mt-0 mx-0 lg:ml-20 min-h-screen">
        <PersonalLibraryHeader
          name={getFullName(userDetails.firstName, userDetails.lastName)}
          username={userDetails.username}
          location={"Nairobi, KE"}
          page={"timeline"}
          noOfFollowers={specificLibrary.numberOfFollowers || 0}
          curriculumLevel={specificLibrary?.curriculumLevel?.level}
          noOfUploads={specificLibrary?.worksheetCount || 0}
          handleRating={handleRating}
          rating={rating}
          libraryType={specificLibrary?.libraryType?.toLowerCase()}
          libraryUser={specificLibrary?.user?.externalId}
        />

        <div className="flex justify-center">
          <div className="flex flex-col w-full max-w-8xl mx-4 md:mx-8 lg:mx-10">
            <div className="body flex z-10 sticky top-16 lg:top-20 py-4 mt-4 pt-2 md:pt-4 lg:pt-4 border-b border-gray-200 dark:border-gray-700">
              <div className="w-full flex gap-6 flex-col justify-between sm:flex-row md:items-center">
                <button
                  className="primary-button rounded-button"
                  onClick={handleUpdateLibraryRoute}
                >
                  <span>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9964 15.9992V10.0005H13.9973V15.9992H11.9964ZM7.9966 15.9992V10.0005H9.9975V15.9992H7.9966ZM4.99975 2.99985V4.99875H2.99985V2.99985H4.99975ZM7.9996 2.99985V4.99875H5.9997V2.99985H7.9996ZM10.9994 2.99985V4.99875H8.99955V2.99985H10.9994ZM15.9992 7.9986H1.9999V5.9987H15.9992V7.9986ZM15.9992 9.9985H17.9991V11.9984H15.9992V9.9985Z"
                        fill="white"
                      />
                      <path
                        d="M1.9999 7.9986V5.9987V1.9999H15.9992V5.9987V7.9986V7.9996H17.9991V1.9999C17.9991 0.894955 17.1031 0 15.9992 0H1.9999C0.895955 0 0 0.894955 0 1.9999V7.9986H1.9999Z"
                        fill="white"
                      />
                      <path
                        d="M13.9993 19.997H1.9999H0V16.2492H1.9999V17.9981H11.9994L15.9992 18.0001V13.9973H17.9991V15.9982V20L13.9993 19.997Z"
                        fill="white"
                      />
                      <path
                        d="M1.9999 13.1243H0.000247943L0 11.2494H1.9999V10.0005L5.62472 12.1869L1.9999 14.3743V13.1243Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Update Library
                </button>

                {
                  // filter, sort, and search commented out below
                  /* <div className="h-14 flex justify-between sm:gap-2 md:gap-4">

                  <div className='flex gap-2'>
                    <button className="primary-button hidden [flex] justify-center items-center w-14 h-14 rounded-full text-white bg-primary-main-700">
                      <svg width="22" height="20" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.36194 16.0001L0.0281563 13.0001L2.47305 13.0001V10.0001H4.25083V13.0372L6.52818 13.0001L3.36194 16.0001Z" fill="white" />
                        <path d="M2.47305 8.76331H4.25083V7.26331H2.47305V8.76331Z" fill="white" />
                        <path d="M12.0293 9.40085H13.8066V3.00012H16.084L12.9178 0.00012207L9.58398 3.00012H12.0289L12.0293 9.40085Z" fill="white" />
                        <path d="M6.02637 6.50012H10.5237L10.5257 5.00012H6.02637V6.50012Z" fill="white" />
                        <path d="M6.02621 8.87844H10.5262L10.5243 7.37844H6.02621V8.87844Z" fill="white" />
                        <path d="M6.02899 11.4009H10.5283L10.5264 9.90085H6.02899V11.4009Z" fill="white" />
                      </svg>
                    </button>

                    <button className="blue-button rounded-button flex justify-center items-center text-white">
                      <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.7507 11.5556C10.7507 12.7156 11.4947 13.6916 12.5284 14.0587V16H14.3062V14.0587C15.3391 13.6916 16.084 12.7156 16.084 11.5556C16.084 10.3956 15.3391 9.41956 14.3062 9.05244V3.55556H12.5284V9.05244C11.4947 9.41956 10.7507 10.3956 10.7507 11.5556ZM14.3062 10.6667V12.4444H12.5284V10.6667H14.3062ZM5.41732 4.44444C5.41732 5.60444 6.16221 6.58044 7.1951 6.94756L7.1951 16H8.97287V6.94756C10.0067 6.58044 10.7507 5.60356 10.7507 4.44444C10.7507 3.28533 10.0058 2.30844 8.97287 1.94133L8.97287 0H7.1951V1.94133C6.16132 2.30844 5.41732 3.28444 5.41732 4.44444ZM8.97287 3.55556V5.33333H7.1951V3.55556H8.97287ZM1.86176 9.05244C0.827984 9.41956 0.0839844 10.3956 0.0839844 11.5556C0.0839844 12.7156 0.827984 13.6916 1.86176 14.0587L1.86176 16H3.63954V14.0587C4.67243 13.6916 5.41732 12.7156 5.41732 11.5556C5.41732 10.3956 4.67332 9.41956 3.63954 9.05244L3.63954 0H1.86176L1.86176 9.05244ZM3.63954 10.6667L3.63954 12.4444H1.86176V10.6667H3.63954ZM14.3062 1.77778L14.3062 0H12.5284L12.5284 1.77778H14.3062Z" fill="white" />
                      </svg>
                    </button>
                  </div>

                  <form className="flex items-center surface pl-4 border rounded border-app-colors-border dark:border-app-colors-border-d focus:ring-app-colors-border dark:focus:ring-app-colors-border-d">
                    <div className="flex">

                      <div className='flex'>
                        <div className="flex items-center pointer-events-none">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                              <path fillRule="evenodd" clipRule="evenodd" d="M10.4886 1.78514C8.10837 -0.595048 4.24932 -0.595048 1.86913 1.78514C-0.511063 4.16533 -0.511063 8.02514 1.86913 10.4046C4.24932 12.7848 8.10913 12.7848 10.4886 10.4046C12.8687 8.02514 12.8687 4.16533 10.4886 1.78514ZM9.41122 9.32724C7.62608 11.1124 4.73084 11.1124 2.94646 9.32724C1.16132 7.54209 1.16132 4.64762 2.94646 2.86248C4.7316 1.07733 7.62684 1.07733 9.41122 2.86248C11.1964 4.64686 11.1964 7.54209 9.41122 9.32724Z" fill="#A3A7C4" />
                              <path d="M12.0957 10.9541L13.1729 9.87616L14.2514 10.9539L13.1742 12.0318L12.0957 10.9541Z" fill="#A3A7C4" />
                              <path d="M14.2538 14.1825C13.9559 14.4812 13.4744 14.4812 13.1765 14.1825L9.94449 10.9505L9.52773 10.5246L9.83021 10.2222L11.6778 8.37454L11.0218 7.71777L9.94449 8.79511L8.8664 9.8732L7.78906 10.9505L8.8664 12.0279L12.0992 15.2599C12.9906 16.1513 14.4405 16.1513 15.3312 15.2599C16.2226 14.3692 16.2226 12.9193 15.3312 12.0279L14.2538 13.1052C14.5517 13.4031 14.551 13.8862 14.2538 14.1825Z" fill="#A3A7C4" />
                            </g>
                          </svg>

                        </div>
                        <input type="search" id="default-search" className="surface py-4 text-sm border-0 focus:ring-0 text-gray-900 dark:text-white dark:placeholder-gray-400" placeholder="Search Library ..." required />
                      </div>

                      <button type="submit" className="primary-button px-4 text-white focus:ring-0 focus:outline-none font-medium rounded text-sm">Search</button>
                    </div>
                  </form>

                </div> */
                }
              </div>
            </div>

            <div className="grid mt-4 mb-6 auto-rows-max gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
              {curriculumSubjects && curriculumSubjects.length > 0 ? (
                curriculumSubjects.map((curriculumSubject, index) => (
                  <ConsolidatedLibraryCard
                    cardName={curriculumSubject.name}
                    key={index + 1}
                    handleClick={() => handleSubjectRoute(curriculumSubject.id)}
                  />
                ))
              ) : (
                <div className="surface flex gap-2 col-span-full border-l-8 border-secondary-red-600 p-4 rounded-md">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 64 64"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_52244_11715)">
                      <path
                        d="M32 0C14.3264 0 0 14.3168 0 31.9776C0 46.3072 9.4272 58.4288 22.4256 62.4992V55.7216C13.0464 51.92 6.4256 42.7424 6.4256 32.0032C6.4256 17.8656 17.888 6.4064 32.0256 6.4064C46.1632 6.4064 57.6256 17.8656 57.6256 32.0032C57.6256 42.7424 51.0016 51.9264 41.6256 55.7216V62.4736C54.5952 58.3936 64 46.288 64 31.9776C64 14.3168 49.6672 0 32 0ZM28.8256 48.0032H35.2256V28.8032H28.8256V48.0032ZM28.8256 22.4032H35.2256V16.0032H28.8256V22.4032ZM28.8256 64H35.2256V57.6H28.8256V64Z"
                        fill="#f8583b"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_52244_11715">
                        <rect width="64" height="64" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>There are no subjects to show</p>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
