import axios from 'axios';
import { CLIENT_API_URL } from '../../../../utils/constants';

import {
  CREATE_SCHOOL_FAILURE,
  CREATE_SCHOOL_LOADING,
  CREATE_SCHOOL_SUCCESS
} from './constants'
import {getJwtTokenCookie} from '../../../../utils/cookieHelper';

export const createSchoolLoading = () => {
  return {
    type: CREATE_SCHOOL_LOADING
  }
}

export const createSchoolSuccess = message => {
  return {
    type: CREATE_SCHOOL_SUCCESS,
    message
  }
}

export const createSchoolFailure = error => {
  return {
    type: CREATE_SCHOOL_FAILURE,
    error
  }
}

export const createSchool = ({ institutionId, curriculaLevelsIds }) => async dispatch => {
  const schoolDetails = {institutionId, curriculaLevelsIds}

  dispatch(createSchoolLoading())
  axios.post(`${CLIENT_API_URL}/account-setup/institutions`, schoolDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    dispatch(createSchoolSuccess(['Institution registered successfully', res.data]));
  })
  .catch(error => {
    dispatch(createSchoolFailure({
      message: 'An error occurred while registering institution'
    }))
  })
}