import {
  GET_SUBJECTS_LOADING,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_CLEAR
} from './constants';

const initialState = {
  loading: false,
  payload: [],
  error: null
};

export default function getSubjects(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case GET_SUBJECTS_LOADING:
      return { ...state, loading: true };
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_SUBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    case GET_SUBJECTS_CLEAR:
      return {
        ...state,
        loading: false,
        payload: []
      };
    default:
      return state;
  }
}