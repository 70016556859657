import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../../../components/navbars/signedOutNavbar";
import Footer from "../../../../components/footer";
import ROUTES from "../../../../utils/routes";
import { getCountriesAction } from "../../../../commonData/countries/action";
import { getCountiesAction } from "../../../../commonData/counties/action";
import { getCurriculaAction } from "../../../../commonData/curricula/action";
import { getSchoolsAction } from "../../../../commonData/schools/action";
import { getCategoriesAction } from "../../../../commonData/categories/action";
import { setStudentPersonalInfo } from "../personalInfo/action";
import SearchableDropDown from "../../../../components/common/searchableDropDown";
import { getGradesAction } from "../../../../commonData/grades/action";
import InformationAccordion from "../../../../components/common/informationAccordion";
import {backgroundImage, isObjEmpty} from '../../../../utils/common';
import { createMissingSchoolAction } from "../../../../commonData/missingSchool/action";
import {getCurriculumSubjectsAction} from '../../../userSpace/personalLibrary/action';

export default function StudentSelectSchool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.getCountries.payload.data);
  const counties = useSelector((state) => state.getCounties.payload.data);
  const schools = useSelector((state) => state.getSchools.payload);
  const studPersonalInfo = useSelector(
    (state) => state.setStudentPersonalInfo.payload
  );
  const curricula = useSelector((state) => state.getCurricula.payload);
  const grades = useSelector((state) => state.getGrades.payload.data);
  const categories = useSelector((state) => state.getCategories.payload);
  const countriesLoading = useSelector((state) => state.getCountries.loading);
  const countiesLoading = useSelector((state) => state.getCounties.loading);
  const schoolsLoading = useSelector((state) => state.getSchools.loading);
  const gradesLoading = useSelector((state) => state.getGrades.loading);
  const categoriesLoading = useSelector((state) => state.getCategories.loading);
  const missingSchool = useSelector(
    (state) => state.createMissingSchool.result.data
  );
  const [formFilled, setFormFilled] = useState(true);
  const [newSchoolDetails, setNewSchoolDetails] = useState({});
  const [pageErrors, setPageErrors] = useState(null);

  const countriesOptions = [];
  const schoolsOptions = [];
  const countiesOptions = [];
  const gradesOptions = [];
  const curriculaOptions = [];
  const categoriesOptions = [];

  countries &&
    countries.map((country) =>
      countriesOptions.push({ label: country.name, value: country.id })
    );

  schools["content"] &&
    schools["content"].map((school) =>
      schoolsOptions.push({ label: school.name, value: school })
    );

  counties &&
    counties.map((county) =>
      countiesOptions.push({ label: county.name, value: county.id })
    );

  grades &&
    grades.map((grade) =>
      gradesOptions.push({ label: grade.name, value: grade })
    );

  curricula["data"] &&
    curricula["data"].map((curriculum) =>
      curriculaOptions.push({ label: curriculum.name, value: curriculum.id })
    );

  categories &&
    categories.map((category) =>
      categoriesOptions.push({ label: category.level, value: category.id })
    );

  useEffect(() => {
    if (!isObjEmpty(missingSchool)) {
      dispatch(setStudentPersonalInfo({...studPersonalInfo, institutionId: missingSchool})
      );
    }
  }, [missingSchool, dispatch]);

  const nextRouteChange = (e) => {
    e.preventDefault();
    if (missingSchool && missingSchool.id) {
      dispatch(
        setStudentPersonalInfo({
          ...studPersonalInfo,
          institutionId: missingSchool.id,
        })
      );
    }
    if ((studPersonalInfo.institutionId || missingSchool) && studPersonalInfo.gradeId) {
      setFormFilled(true);
    } else {
      return setFormFilled(false);
    }
    let path = ROUTES.studentSignUpAuthentication;
    navigate(path);
  };

  const backRouteChange = () => {
    let path = ROUTES.studentSignUpSelectSchool;
    navigate(path);
  };

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch]);

  const handleCountryChange = useCallback((code) => {
    (async () => dispatch(getCountiesAction(code)))();
    (async () => dispatch(getCurriculaAction(code)))();
  }, [dispatch]);

  const handleCountyChange = (countyId) => {
    setNewSchoolDetails({ ...newSchoolDetails, countyId: countyId });
    (async () => dispatch(getSchoolsAction(countyId)))();
  };

  const handleCurriculumChange = (curriculumId) => {
    dispatch(
      setStudentPersonalInfo({
        ...studPersonalInfo,
        curriculumId: curriculumId,
      })
    );
    (async () => dispatch(getCategoriesAction(curriculumId)))();
  };

  const handleSchoolChange = (institutionId) => {
    dispatch(
      setStudentPersonalInfo({
        ...studPersonalInfo,
        institutionId: institutionId,
      })
    );
  };

  const handleCategoryChange = (categoryId) => {
    dispatch(
      setStudentPersonalInfo({
        ...studPersonalInfo,
        curriculumLevelId: categoryId,
      })
    );
    ;
    (async () => dispatch(getGradesAction(categoryId)))();
  };

  const handleGradeChange = (gradeId) => {
    dispatch(setStudentPersonalInfo({ ...studPersonalInfo, gradeId: gradeId }));
  };

  const handleChange = (e) => {
    setNewSchoolDetails({
      ...newSchoolDetails,
      [e.target.name]: e.target.value,
    });
  };

  const addNewSchool = (e) => {
    e.preventDefault();
    if (newSchoolDetails.countyId && newSchoolDetails.name) {
      const schoolData = {
        countyId: newSchoolDetails.countyId,
        name: newSchoolDetails.name,
      };
      (async () => {
        const response = await dispatch(createMissingSchoolAction(schoolData));
        if (response) {
          dispatch(
            setStudentPersonalInfo({
              ...studPersonalInfo,
              institutionId: response.id,
            })
          );
        }
      })();
    } else {
      setPageErrors("Please enter the missing school name and select a county");
    }
  };

  console.log("Student Personal info", studPersonalInfo);

  return (
    <div
      className="bg-primary-900 dark:bg-primary-950 bg-no-repeat bg-cover bg-center w-full h-full min-h-screen bg-blend-overlay"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center w-full h-full min-h-screen">
        <NavBar />
        <div className="flex flex-grow w-full max-w-9xl px-4">
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="w-full lg:order-last flex flex-1 h-full justify-center lg:justify-end items-center">
              <div className="flex flex-col gap-4 w-full sm:max-w-md">
                {!formFilled ? (
                  <div className="surface text-center text-secondary-red-500 text-md py-4 rounded-md">
                    Please fill in the missing fields
                  </div>
                ) : null}

                <div className="border border-gray-400 dark:border-gray-700 surface flex flex-col gap-6 p-2 sm:p-3 md:p-4 lg:p-5 rounded-md">
                  <div className="flex justify-center w-full">
                    <h6 className="py-2 uppercase">Select your school</h6>
                  </div>

                  <div className="flex flex-col gap-6">
                    <form className="flex flex-col gap-6">
                      <SearchableDropDown
                        id="countryIso2Code"
                        name="countryIso2Code"
                        options={
                          countriesOptions.length > 0 ? countriesOptions : []
                        }
                        handleChange={handleCountryChange}
                        loading={countriesLoading}
                        borderText={`School Country ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="countyId"
                        name="countyId"
                        options={
                          countiesOptions.length > 0 ? countiesOptions : []
                        }
                        handleChange={handleCountyChange}
                        loading={countiesLoading}
                        borderText={`County/State ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="institutionId"
                        name="institutionId"
                        options={
                          schoolsOptions.length > 0 ? schoolsOptions : []
                        }
                        handleChange={handleSchoolChange}
                        loading={schoolsLoading}
                        borderText={`School ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />

                      <div className="flex items-center justify-center gap-4">
                        <p className="text-sm font-medium inline-block">
                          Can't find your school?
                        </p>

                        <input
                          type="text"
                          name="name"
                          id="school"
                          value={newSchoolDetails.name || ""}
                          className="line-clamp-1 flex-1 text-xs py-4 surface w-full my-auto focus:outline-none focus:shadow-none focus:ring-0 outline-none rounded-md borders text-app-colors-p-normal dark:text-app-colors-p-normal-d dark:placeholder-app-colors-p-small-d placeholder-app-colors-p-small"
                          placeholder="Enter school name"
                          onChange={handleChange}
                        />

                        <button
                          onClick={addNewSchool}
                          className="pill-button primary-button opacity-50 hover:opacity-70"
                        >
                          Add
                        </button>
                      </div>
                      {pageErrors && (
                        <div className="text-secondary-red-500 text-xs italic text-center">
                          {pageErrors}
                        </div>
                      )}

                      <SearchableDropDown
                        id="curriculumId"
                        name="curriculumId"
                        options={
                          curriculaOptions.length > 0 ? curriculaOptions : []
                        }
                        handleChange={handleCurriculumChange}
                        loading={categoriesLoading}
                        borderText={`Curriculum ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="categoryId"
                        name="categoryId"
                        options={
                          categoriesOptions.length > 0 ? categoriesOptions : []
                        }
                        handleChange={handleCategoryChange}
                        loading={categoriesLoading}
                        borderText={`School Level ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />

                      <SearchableDropDown
                        id="gradeId"
                        name="gradeId"
                        options={gradesOptions.length > 0 ? gradesOptions : []}
                        handleChange={handleGradeChange}
                        loading={gradesLoading}
                        borderText={`Grade ${!formFilled && !studPersonalInfo.institutionId ? "*" : ""}`}
                      />
                    </form>
                    <div className="flex justify-end">
                      <button
                        className="hidden outline-pill-button primary-light-button px-9"
                        onClick={backRouteChange}
                      >
                        Back
                      </button>
                      <button
                        className="pill-button primary-button px-9"
                        onClick={(e) => nextRouteChange(e)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-center self-end">
              <InformationAccordion />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
