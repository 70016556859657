import axios from 'axios';
import {CLIENT_API_URL} from '../../../utils/constants';
import {
  GET_LIBRARIES_LOADING,
  GET_LIBRARIES_FAILURE,
  GET_LIBRARIES_SUCCESS,
} from './constants';
import {getJwtTokenCookie} from '../../../utils/cookieHelper';

export const getLibrariesLoading = () => ({
  type: GET_LIBRARIES_LOADING
});

export const getLibrariesSuccess = payload => ({
  type: GET_LIBRARIES_SUCCESS,
  payload
});

export const getLibrariesFailure = error => ({
  type: GET_LIBRARIES_FAILURE,
  error
});

export const getLibrariesAction = () => async dispatch => {
  dispatch(getLibrariesLoading());
  axios.get(`${CLIENT_API_URL}/libraries`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    dispatch(getLibrariesSuccess(res.data));
  })
  .catch(error => {
    dispatch(getLibrariesFailure({
      message: 'An error occurred while fetching libraries'
    }))
  })
}
