// /api/v1/accounts/curricula/grades?categoryId={{categoryId}}
import axios from 'axios';

import {
  GET_GRADES_LOADING,
  GET_GRADES_SUCCESS,
  GET_GRADES_FAILURE
} from './constants';
import {CLIENT_API_URL} from '../../utils/constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getGradesLoading = () => ({
  type: GET_GRADES_LOADING
})

export const getGradesSuccess = payload => ({
  type: GET_GRADES_SUCCESS,
  payload
})

export const getGradesFailure = error => ({
  type: GET_GRADES_FAILURE,
  error
})

export const getGradesAction = (categoryId) => async dispatch => {
  dispatch(getGradesLoading())

  axios.get(`${CLIENT_API_URL}/grades?curriculumLevelId=${categoryId}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    dispatch(getGradesSuccess(res.data));
  })
  .catch(error => {
    dispatch(getGradesFailure({
      message: 'An error occurred while fetching grades'
    }))
  })
}

