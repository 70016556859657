import {
  SET_AFFILIATION_SUCCESS,
  SET_AFFILIATION_FAILURE,
    GET_AFFILIATION_SUCCESS
} from './constants';
import {isObjEmpty} from '../../../../../utils/common';

export const setAffiliationSuccess = payload => {
  return {
    type: SET_AFFILIATION_SUCCESS,
    payload
  }
}

export const setAffiliationFailure = error => {
  return {
    type: SET_AFFILIATION_FAILURE,
    error
  }
}

export const getAffiliationsSuccess = payload => {
  return {
    type: GET_AFFILIATION_SUCCESS,
    payload
  }
}

export const setAffiliationAction = (affiliation) => async dispatch => {
  if (!isObjEmpty(affiliation)) {
    await dispatch(setAffiliationSuccess(affiliation))
  } else {
    await dispatch(setAffiliationFailure({message: 'Failed to set affiliation'}))
  }
}


export const getAffiliationsAction = () => async dispatch => {
  await dispatch(getAffiliationsSuccess())
}
