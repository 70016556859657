import React, { useState } from "react";

const contentA = (
  <p className="small">
    Ticet Edu fosters a strong collaborative environment by providing users with
    the tools to create, share, and engage with content in diverse ways. Whether
    it's through content creation, study groups, or discussions, the platform
    encourages teamwork and the sharing of knowledge. By promoting collaboration
    among educators, students, and professionals, Ticet facilitates a sense of
    community, enabling individuals to work together toward common learning
    goals.
  </p>
);

const contentB = (
  <p className="small">
    Ticet Edu engages users through interactive features such as personalized
    learning paths, badges, points, and gamification elements. These tools
    motivate learners to stay involved and invested in their educational
    journey. The platform also offers social learning features, allowing users
    to connect with peers, participate in discussions, and provide feedback,
    making the learning experience dynamic, rewarding, and enjoyable.
  </p>
);

const contentC = (
  <p className="small">
    At its core, Ticet Edu prioritizes effective learning through personalized
    content, flexible learning paths, and AI-powered insights. The platform
    enables users to learn at their own pace, catering to individual learning
    styles and needs. By combining collaborative and interactive features with
    in-depth analytics, Ticet ensures that every learner can access relevant
    materials and track their progress, ultimately enhancing the learning
    experience and outcomes.
  </p>
);

const InformationAccordion = ({
  subTittle = "LEARNING EXPERIENCE PLATFORM",
  title1 = "Collaborate",
  content1 = contentA,
  title2 = "Engage",
  content2 = contentB,
  title3 = "Learn",
  content3 = contentC,
}) => {
  const [expandedSection, setExpandedSection] = useState(1);

  const handleToggle = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const getButtonClasses = (section) => {
    const baseClasses =
      "group surface surface-hover items-center py-3 px-2 w-full text-left focus:outline-none";
    const activeClasses =
      "bg-secondary-yellow-500 dark:bg-secondary-yellow-500 text-white dark:text-white";
    return expandedSection === section
      ? `${baseClasses} ${activeClasses}`
      : baseClasses;
  };

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <title className="text-white dark:text-white">Ticet EDU</title>
        <h5 className="text-white dark:text-white">{subTittle}</h5>
        <div className="">
          <p className="text-white dark:text-white">
            Ticet EDU is a learning experience platform that blends social,
            collaborative, and educational technologies. It provides an
            abundance of resources to create a thriving community of learners.
            The platform seeks to foster social interactions, collaborative
            learning, and knowledge-sharing.
          </p>
        </div>
      </div>
      <div id="accordion-flush">
        {[
          { title: title1, content: content1, id: 1 },
          { title: title2, content: content2, id: 2 },
          { title: title3, content: content3, id: 3 },
        ].map(({ title, content, id }) => (
          <div key={id}>
            <h2 id={`accordion-flush-heading-${id}`}>
              <button
                type="button"
                className={getButtonClasses(id)}
                onClick={() => handleToggle(id)}
                aria-expanded={expandedSection === id}
                aria-controls={`accordion-flush-body-${id}`}
              >
                <h6>{title}</h6>
              </button>
            </h2>
            <div
              id={`accordion-flush-body-${id}`}
              className={expandedSection === id ? "block" : "hidden"}
              aria-labelledby={`accordion-flush-heading-${id}`}
            >
              <div className="py-3 px-2 surface">{content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationAccordion;
