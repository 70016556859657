import axios from 'axios';
import { CLIENT_API_URL } from '../../../../utils/constants';

import {
  CREATE_STUDENT_FAILURE,
  CREATE_STUDENT_LOADING,
  CREATE_STUDENT_SUCCESS
} from './constants'
import {getJwtTokenCookie} from '../../../../utils/cookieHelper';

export const createStudentLoading = () => {
  return {
    type: CREATE_STUDENT_LOADING
  }
}

export const createStudentSuccess = message => {
  return {
    type: CREATE_STUDENT_SUCCESS,
    message
  }
}

export const createStudentFailure = error => {
  return {
    type: CREATE_STUDENT_FAILURE,
    error
  }
}

export const createStudent = ({ institutionId, curriculumId, parentUsername, curriculumLevelId, gradeId }) => async dispatch => {
  const studentDetails = {institutionId, curriculumId, parentUsername, curriculumLevelId, gradeId}

  dispatch(createStudentLoading())
  axios.post(`${CLIENT_API_URL}/account-setup/students`, studentDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    if (res.status !== 201) return dispatch(createStudentFailure('An error occurred while registering student'))
    dispatch(createStudentSuccess('Student registered successfully'))
  })
  .catch(error => {
    dispatch(createStudentFailure('An error occurred while registering student'))
  })
}