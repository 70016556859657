import React from "react";
import { useNavigate } from "react-router-dom";

import NavBar from "../../components/navbars/signedOutNavbar";
import Footer from "../../components/footer";
import ROUTES from "../../utils/routes";
import { backgroundImage } from "../../utils/common";

export default function PageNotFound() {
  const navigate = useNavigate();

  const home = () => {
    let path = ROUTES.home;
    navigate(path);
  };

  return (
    <div className="body flex flex-col items-center w-full h-full min-h-screen">
      <div
        className={` pt-8 flex flex-col h-full w-full px-4 md:px-6 lg:px-8 items-center justify-center bg-primary-900 dark:bg-primary-950 bg-cover bg-blend-overlay bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/ticet_bg2.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 30s linear infinite",
        }}
      >
        <NavBar />
        <title className="text-app-colors-heading-d dark:text-app-colors-heading-d font-medium">
          Oops! 404 Error
        </title>
        <div className=" mb-8 text-white font-bold ">
          <span
            onClick={home}
            className="   text-white font-semibold cursor-pointer hover:text-secondary-blue-600"
          >
            Home
          </span>
          <span className=""> / 404 Error</span>
        </div>
      </div>

      <div className="flex gap-6 flex-col lg:flex-row flex-grow justify-center items-center h-full w-full max-w-8xl">
        <div className="flex flex-1 justify-end items-center h-full px-10">
          <img src="/images/404.png" alt="error 404" />
        </div>
        <div className="flex gap-8 flex-col flex-1 justify-start items-center h-full px-10">
          <title className="text-5xl text-center font-semi-bold">
            Page Not Found
          </title>
          <title className="text-3xl text-center">
            A <span className="text-secondary-red-500">wild ghost</span>{" "}
            appears! Sadly, not what you were looking for...
          </title>
          <p className="small text-center">
            Sorry! The page you were searching for has been spirited away, like
            a cosmic traveler lost in the nebula. The URL appears to have been
            moved, deleted, or perhaps mistyped. If you like, you can return to
            our homepage, or if the problem persists and you believe the fault
            is with us, send us an email to:{" "}
            <a href="mailto:info@ticetedu.com">info@ticetedu.com</a>
          </p>
          <p className="small text-center">
            Remember, even in the vast expanse of cyberspace, some pages choose
            to wander beyond our reach. 🚀🌌👻
          </p>
          <button
            onClick={home}
            className="pill-button primary-button cursor-pointer"
          >
            Go back home
          </button>
        </div>
      </div>
      <div className="flex-end">
        <Footer />
      </div>
    </div>
  );
}
