import {
  UPLOAD_WORKSHEET_LOADING,
  UPLOAD_WORKSHEET_FAILURE,
  UPLOAD_WORKSHEET_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  payload: [],
  error: null
};

export default function uploadWorksheet(state = initialState, action) {
  const { type, payload, error } = action;
  switch(type) {
    case UPLOAD_WORKSHEET_LOADING:
      return { ...state, loading: true };
    case UPLOAD_WORKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case UPLOAD_WORKSHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}
