import React from "react";
import { useSelector } from "react-redux";
import { getImageData } from "../../utils/common";

const ContentHeader = ({ subject, title, owner }) => {
  const uploadInfo = useSelector((state) => state.uploadImage);
  const profileImageData = getImageData(uploadInfo.payload, "profile");

  return (
    <div
      className={`mt-16 lg:mt-20 ml-0 pt-8 flex flex-col h-72 w-full px-4 md:px-6 lg:px-8 items-center justify-center bg-primary-900 dark:bg-primary-950 bg- bg-blend-overlay bg-center`}
      style={{
        backgroundImage: `url("/images/bg-section1.png")`,
        backgroundRepeat: "repeat-x",
      }}
    >
      <div className="flex w-full max-w-8xl">
        <div
          className="h-60 md:h-64 lg:h-72 mt-0"
          style={{ background: `url("/images/library_bg.png")` }}
        >
          <div className="h-full flex flex-col gap-8">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              {subject}
            </title>
            <div>
              <h4 className="text-app-colors-heading-d dark:text-app-colors-heading-d line-clamp-3">
                {title}
              </h4>
              <div className="flex gap-2 items-center">
                <img
                  src={
                    profileImageData
                      ? profileImageData["assetUrl"]
                      : "/images/test-avatar.jpg"
                  }
                  alt="profile"
                  className="h-10 w-10 rounded-full"
                />
                <p className="text-app-colors-heading-d dark:text-app-colors-heading-d font-medium">
                  @{owner}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
