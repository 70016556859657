import {
  DELETE_UNCATEGORIZED_WORKSHEET_LOADING,
  DELETE_UNCATEGORIZED_WORKSHEET_FAILURE,
  DELETE_UNCATEGORIZED_WORKSHEET_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  message: {},
  error: null
};

export default function deleteUncategorizedWorksheet(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case DELETE_UNCATEGORIZED_WORKSHEET_LOADING:
      return { ...state, loading: true };
    case DELETE_UNCATEGORIZED_WORKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case DELETE_UNCATEGORIZED_WORKSHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
}