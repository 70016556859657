import { createStore, compose, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import rootReducer from '../rootReducer';

// Configure redux-persist
const persistConfig = {
  key: 'root',
  storage: storage('ticet-frontend'),  // Use IndexedDB for large data persistence
  whitelist: [
    'getSubjects',
    'getLibraries',
    'getUserDetails',
    'getGradeChapters',
    'getSuggestedLibraries',
    'getWorksheets',
    'setSelectedSetting',
    'getSingleWorksheet',
    'getCurriculumSubjects'
  ],  // Reducers that need to be persisted in the state
};

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = () => {
  const enhancers = [];
  const middlewares = [
    thunk,
  ];

  if (process.env.NODE_ENV === 'development') {
    enhancers.push(applyMiddleware(logger));
  }

  const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);

  // Create Redux store with the persisted reducer
  const store = createStore(persistedReducer, composedEnhancers);

  // Create a persistor instance
  const persistor = persistStore(store);

  return { store, persistor };
}

export default configStore;
