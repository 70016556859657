import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="mb-6">
        <h6 className="text-base mb-2">Prelude</h6>

        <p className="small mb-2">
          Welcome to Ticet Edu Limited (“Ticet,” “We,” “Our,” “Us”). These Terms
          of Use (“Terms”) set out the standards and expectations that guide
          your use of our platform, website, and any products or services we
          provide (collectively referred to as “the Services”). By accessing or
          using the Services, you agree to abide by these Terms, which establish
          the legal agreement between you and Ticet. This policy ensures a
          secure, transparent, and productive environment for all users and is
          supplemented by our{" "}
          <a href="https://www.about.ticetedu.com/legal-policies/legal-policies.html#data-protection">
            Data Protection Policy
          </a>{" "}
          and
          <a href="https://www.about.ticetedu.com/legal-policies/legal-policies.html#privacy">
            {" "}
            Privacy Policy
          </a>
          . The policy may change, but whenever it does, we will indicate when
          it was last revised as hereunder.
        </p>

        <p className="small mb-2">
          We have created this Terms of Use Policy to help you understand your
          rights and responsibilities while using our platform,
          https://www.ticetedu.com, its subdomains, and any products or services
          offered by us (collectively called “the services”).
        </p>

        <p className="small mb-2">
          If you are below 18 years of age, please share this Terms of Use
          Policy with your parent or guardian.
        </p>

        <p className="small mb-2">
          This Terms of Use Policy was last revised on 1st August 2024.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">1. Introduction</h6>

        <p className="small mb-2">
          Thank you for using the Ticet Edu Platform ( the Platform ) and the
          features, products, and services ( the Content ) we make available for
          you on the Platform.Please read these Terms of Service [TOU] carefully
          as they govern your use of the Platform, including our website and any
          software application currently developed or that will be developed in
          the future, as well as any content made available on the Ticet Edu
          platform.
        </p>

        <p className="small mb-2">
          By signing up to, registering for, subscribing to, accessing,
          browsing, uploading to, downloading from, or using any of the Content
          that we offer, you acknowledge that you have read, understood, and
          agree to be bound by these Terms of Service.
        </p>

        <p className="small mb-2">
          These Terms of Use constitute a legally binding agreement between you
          and Ticet Edu Limited. If you do not understand these terms of service
          or any part of them, you must not use the Ticet Edu Platform or access
          any Content.
        </p>

        <p className="small mb-2">
          Ticet reserves the right, in our sole discretion, to change these
          Terms at any time. Updated versions of the Terms will be posted on our
          website and will be effective immediately upon posting. The date of
          the latest version is indicated below. We will also notify you of any
          changes via email. Please check back frequently, especially before
          using our website, to see if these Terms have changed. Your continued
          use of the website after any changes to the Terms constitutes your
          acceptance of such changes. Any changes to these Terms will supersede
          the prior version for all activity occurring after the revised version
          has been made available.
        </p>
      </div>

      <div className="mb-4">
        <h6 className="text-base mb-2">2. Service Provider</h6>

        <p className="small mb-2">
          These terms are between the user ("You" "Your") and Ticet Edu Limited
          ("We" "Us" "Our"), a limited liability company operating under the
          laws of the Republic of Kenya. Any questions or queries regarding
          these Terms can be sent to info@ticetedu.com.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">3. Age and Eligibility Requirements</h6>

        <p className="small mb-2">
          You may use the platform if you are at least 13 years old. However, if
          you are under 18 (minor), you must have your parent or legal guardian
          s permission to use the Service. A parent or guardian authorizes and
          gives his/her permission by creating an account on behalf of the
          minor. Please have your parent/guardian read and accept this Agreement
          with you if you are below the age of 18. If you are a parent or legal
          guardian of a minor, you hereby agree to bind the minor to these Terms
          and to fully indemnify and hold harmless Ticet if the minor breaches
          any of these terms. If Ticet Edu discovers any user is a minor without
          parental consent, their accounts may be suspended or terminated, and
          any personal information collected deleted in accordance with our
          privacy policy.
        </p>

        <p className="small mb-2">
          You must have the power to enter a binding contract with us and not be
          barred from doing so under any applicable laws. You must also reside
          in a country where our service is available. You warrant that any
          registration information you submit to us is true, accurate, and
          complete, and you agree to keep it that way at all times.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">4. Jurisdiction</h6>

        <p className="small mb-2">
          Our operations are based in Kenya, where we operate as Ticet Edu
          Limited. The services we offer may not be appropriate or available for
          use in some jurisdictions beyond Kenya. If You use or access the
          Content in a country other than Kenya, You do so at Your own risk, and
          You are responsible for complying with local laws, rules, and
          regulations. We may limit the availability of the Content, in whole or
          in part, to any person, geographic area, or jurisdiction we choose, at
          any time and at our sole discretion.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">5. The Service Provided by Us</h6>

        <p className="small mb-2">
          We describe our Platform as a Learning Experience Platform (LXP) that
          is designed to help individuals students and teachers alike create,
          gain access to, and share curriculum-approved educational content.
          Registration to the Ticet Edu Platform is free-of-charge. However,
          accessing certain components of the Platform may require payment (
          paid subscription ). From time to time, we may also offer special
          promotional plans, memberships, or services. If you cancel or
          interrupt your paid subscription (e.g., by changing payment details),
          your account may be suspended, and you may lose the ability to
          resubscribe to and access the paid Content.{" "}
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">
          6. Platform Limitation and Modification
        </h6>
        <p className="small mb-2">
          We use reasonable care and skill to keep the Platform operational and
          to provide you with a seamless, interactive learning experience.
          However, our Content offerings and their availability may change from
          time to time and subject to applicable laws, without liability to you;
          for example:
        </p>
        <ul className="small mb-2 ml-8 list-disc">
          <li className="small">
            The Platform may experience temporary interruptions due to technical
            difficulties, maintenance, testing, or updates, including those
            required to reflect changes in relevant laws and regulatory
            requirements.
          </li>
          <li className="small">
            We aim to evolve and improve the Platform constantly, and we may
            modify, suspend, or stop (permanently or temporarily) providing all
            or part of our services (including particular functions, features,
            subscription plans, and any promotional offerings).
          </li>
          <li className="small">
            Ticet Edu has no obligation to provide any specific content through
            the Platform, and we, or the applicable owners, may remove
            particular content without prior notice.
          </li>
        </ul>
        <p className="small mb-2">
          Any significant changes to the Platform or the Terms of Use,
          especially those that may impact your use of the services will be
          communicated via email.
        </p>
        <p className="small mb-2">
          Ticet Edu has no liability to you, nor any obligation to provide a
          refund to you, in connection with internet or other service outages or
          failures caused by the actions of government authorities, other third
          parties or events beyond our control.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">7. Your Use of Our Platform</h6>

        <p className="small mb-2">
          To use all or part of the Content we provide, you may need to create
          an account with us. Your username and password are for personal use
          only and must be kept confidential. You are responsible for all
          activity associated with your account, including any unauthorized use.
          Unauthorized uses include, but are not limited to, sharing your login
          credentials with others, allowing others to access your account, or
          using your account for illegal activities. If your username or
          password is lost, stolen, or you suspect unauthorized access, notify
          us immediately.
        </p>

        <p className="small mb-2">
          Failure to keep your account secure or providing false information
          during account creation may result in the suspension or termination of
          your account. We reserve the right to reclaim or require you to change
          your username or password for any reason, including to protect the
          security of the platform or to comply with legal requirements.
          Non-compliance with these security responsibilities may lead to
          restricted access or permanent loss of your account.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">8. Platform Integrity</h6>

        <p className="small mb-2">
          We aim to create a learning environment where everyone can access and
          engage with educational content on Ticet Edu comfortably and safely.
          To support this goal, we have established guidelines that govern the
          appropriate use of our Platform. In addition to the general guidelines
          provided below, further restrictions may be developed and shared on
          our website. We reserve the right, at our sole discretion, to
          determine whether a rule has been violated and to what extent.{" "}
        </p>

        <p className="small mb-2">
          If you encounter any misuse or violations of these Terms, including
          breaches of security or inappropriate content, please report the issue
          immediately to Ticet Edu through our designated reporting channels,
          such as our support email (support@ticetedu.com) or in-platform
          reporting tools. When a violation is reported, our team will promptly
          review the matter and take appropriate action, which may include
          issuing warnings, suspending or terminating accounts, or pursuing
          legal action if necessary.
        </p>

        <p className="small mb-2">By using the platform, you warrant that:</p>

        <ul className="small mb-2 ml-8 list-disc">
          <li className="small">
            You are at least thirteen (13) years old and have created an account
            as guided by a parent/guardian if under the age of eighteen (18);
          </li>

          <li className="small">
            You will not use the Platform for any illegal purpose or in
            violation of any county, national, or international law, including,
            without limitation, laws governing intellectual property and other
            proprietary rights, and data protection and privacy;
          </li>

          <li className="small">
            You will not use the Platform for any purpose other than to post,
            receive, and engage with original and/or appropriately licensed
            content and to add descriptive information to original content;
          </li>

          <li className="small">
            You will not impersonate or attempt to impersonate any person or
            entity, falsely claim an affiliation with any person or entity,
            forge another person s digital signature, misrepresent the source,
            identity, or content of information transmitted via the Platform, or
            perform any other similar fraudulent activity;
          </li>

          <li className="small">
            You will not access the Accounts and/or Subscriptions of others
            without permission;
          </li>

          <li className="small">
            You will not delete, modify, or alter the copyright or other
            proprietary rights notices provided by the Platform or associated
            with any content available via the Platform;
          </li>

          <li className="small">
            You will not make unsolicited offers, advertisements, proposals, or
            send junk mail or spam to other users of the Platform;
          </li>

          <li className="small">
            You will not defame, harass, abuse, menace, threaten or defraud
            users of the Platform;
          </li>

          <li className="small">
            You will not collect, or attempt to collect, personal information
            about users or third parties without their consent;
          </li>

          <li className="small">
            You will not remove, circumvent, disable, damage, or otherwise
            interfere with rights enforcement and other security-related
            features that prevent or restrict the use or copying, printing,
            storing, transferring, or sharing of any content accessible through
            the Platform or features that enforce limitations on the use of the
            Platform or any content available via the Platform;
          </li>

          <li className="small">
            You will not modify, adapt, translate, or create derivative works
            based upon works provided through the Platform, or any part thereof,
            except and only to the extent that such activity is expressly
            permitted by applicable law notwithstanding this limitation;
          </li>

          <li className="small">
            You will not use any robot, spider, scraper, or other automated
            means to access the Platform or copy, print, access, store,
            transfer, or share any content accessible through the Platform for
            any purpose or to bypass any measures we may use to prevent or
            restrict access, or the ability to copy, print, access, store,
            transfer, or share content;
          </li>

          <li className="small">
            You will not forge headers or otherwise manipulate identifiers to
            disguise the origin of any content transmitted through the services;
          </li>

          <li className="small">
            You will not misuse any reporting, flagging, complaint, dispute, or
            appeals process, including by making groundless, vexatious, or
            frivolous submissions;
          </li>

          <li className="small">
            You will not interfere with or disrupt servers or networks related
            to the operation of the Platform or disobey any requirements,
            procedures, policies, or regulations of networks connected to the
            Platform;
          </li>

          <li className="small">
            You will not clone, copy, duplicate, or create a database of the
            Platform by systematically downloading and storing all or any
            content provided through the Platform;
          </li>

          <li className="small">
            You will not run contests, sweepstakes, or other similar events on
            or through the Platform without Our prior written permission; and
          </li>

          <li className="small">
            You will not use any portion of any content provided through the
            Platform to train a large language model.
          </li>
        </ul>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">9. License Grant</h6>

        <h6 className="text-sm">Us to You</h6>
        <p className="small mb-2">
          Subject to your compliance with these terms (including any other
          applicable terms and conditions), we grant you limited, non-exclusive,
          revocable permission to make personal, non-commercial use of the
          Platform. We also grant you a limited, non-exclusive,
          non-transferable, freely revocable license to view, download
          (including, without limitation, to a portable device), print, and have
          printed select user-generated content (collectively,
          &quot;Access&quot;) for personal use, except as we may restrict or
          block at the request of the uploader or on its initiative. This Access
          shall remain in effect unless and until you or Ticet Edu terminate it.
        </p>
        <p className="small mb-2">
          &quot;Personal use&quot; means accessing, viewing, downloading, and
          printing content from the Platform solely for your private, individual
          use. This does not include any use for business purposes,
          profit-making, or redistribution to others, whether for free or for
          sale.
        </p>
        <p className="small mb-2">
          You are strictly prohibited from using the Platform or any Content for
          commercial purposes, including but not limited to reselling,
          sublicensing, distributing, redistributing, or publicly displaying the
          content without explicit permission from Ticet Edu. Additionally, you
          may not transfer your access rights to another person or entity. Any
          attempt to redistribute, transfer, or commercially exploit the Content
          without authorization will result in the termination of your access
          and may lead to legal action.
        </p>

        <h6 className="text-sm">You to Us</h6>
        <p className="small mb-2">
          You retain ownership of the content you post to the Platform. However,
          to make your content available on the Platform, we require a limited
          license from you. By posting content, you grant Ticet Edu a
          non-exclusive, transferable, sub-licensable, royalty-free, fully paid,
          irrevocable, worldwide license to use your content. This includes the
          right to reproduce, distribute, perform, display, translate, modify,
          create derivative works from, and otherwise use your content through
          any medium. Ticet Edu may use your content in various ways, such as
          featuring it in promotional materials, incorporating it into
          educational resources, or displaying it alongside other content on the
          Platform.
        </p>
        <p className="small mb-2">
          Where applicable, and to the extent permitted by law, you also agree
          to waive any &quot;moral rights&quot; or equivalent rights, such as
          the right to be identified as the author and the right to object to
          modifications that you might consider derogatory.
        </p>
        <p className="small mb-2">
          If you wish to withdraw your content from the Platform, you may do so
          by deleting the content from your account, deleting your account or
          contacting us with a request for removal. While we will make
          reasonable efforts to remove your content, please note that certain
          uses of your content, such as in previously distributed materials or
          derivative works, may continue even after removal from the Platform
        </p>

        <h6 className="text-sm">You to Others</h6>
        <p className="small mb-2">
          You also grant each user of the Ticet Edu Platform a worldwide,
          non-exclusive, royalty-free license to access your content through the
          Platform and to use that content (including to reproduce, distribute,
          modify, display, and perform it) only as permitted by the
          functionalities or features of the Platform, such as through a
          subscription or specific tools provided by Ticet Edu. This license
          does not grant other users the right to use your content outside of
          the Platform or in any manner not enabled by the Platform s
          functionalities.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">10. Intellectual Property</h6>

        <p className="small mb-2">
          All of Ticet Edu s trademarks, service marks, trade names, logos,
          domain names, and any other features of the Ticet brand (&quot;Ticet s
          Intellectual Property&quot;) are the sole property of Ticet Edu. These
          Terms do not grant you any rights to use any of Ticet s Intellectual
          Property, whether for commercial or non-commercial use.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">11. Monetization</h6>

        <p className="small mb-2">
          You grant Ticet Edu the right to monetize your Content on the
          Platform. This Agreement does not entitle you to any payments.
          However, any payments you may be entitled to receive from Ticet Edu
          under any other agreement between you and Us (for example, through a
          subscription to your library or payments for your content) will be
          treated as royalties. If required by law, Ticet Edu will withhold
          taxes from such payments.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">12. Deleting/Removing Content</h6>

        <p className="small mb-2">
          You may remove or delete your content from the platform at any time if
          you no longer wish to have your content accessed by other users or if
          you no longer wish to be bound by these terms.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">13. Payments and Cancelation</h6>

        <h6 className="text-sm">Billing</h6>
        <p className="small mb-2">
          You may purchase a Paid Subscription directly from Ticet Edu by paying
          a subscription fee in advance every month or some other recurring
          interval disclosed to you before your purchase
        </p>
        <p className="small mb-2">
          Tax rates are calculated based on the information you provide and the
          applicable rates stipulated by the relevant laws at the time of your
          monthly charge.
        </p>
        <p className="small mb-2">
          Suppose you purchase a Paid Subscription using a code, gift card,
          pre-paid offer, or other offer provided or sold by or on behalf of
          Ticet Edu for access to a Paid Subscription (&quot;Codes&quot;). In
          that case, you hereby agree to abide by the terms and conditions set
          out by Us in relation to such a purchase.
        </p>

        <h6 className="text-sm">Price and Tax Changes</h6>
        <p className="small mb-2">
          Ticet Edu may, from time to time, change the price for the Paid
          Subscriptions, including recurring subscription fees or Codes (defined
          above), and will communicate any price changes to you in advance with
          reasonable notice. Price changes will take effect at the start of the
          next subscription period following the date of the price change.
          Subject to applicable law, you will accept the new price if you
          continue to use the Ticet Service after the price change takes effect.
          If you do not agree to a price change, you can reject the change by
          unsubscribing from the applicable Paid Subscription before the price
          change goes into effect.
        </p>
        <p className="small mb-2">
          Tax rates are based on the rates applicable at the time of your
          monthly charge. These amounts can change over time depending on local
          tax requirements in Kenya. Any change in tax rate will be
          automatically applied based on the account information you provide.
        </p>

        <h6 className="text-sm">Renewal and Cancellation</h6>
        <p className="small mb-2">
          Except for Paid Subscriptions for a Pre-Paid Period, your payment to
          Ticet Edu or the third party through which you purchased the Paid
          Subscription will automatically renew at the end of the applicable
          subscription period unless you cancel your Paid Subscription before
          the end of the then-current subscription period.
        </p>
        <p className="small mb-2">
          Contact our Customer Support team for instructions on how to cancel.
          The cancellation will take effect the day after the last day of the
          current subscription period, and you will be downgraded to the
          accessible version of the Ticet Edu Service. We do not provide refunds
          or credits for any partial subscription periods except as expressly
          stated in these Terms.
        </p>
        <p className="small mb-2">
          If you have purchased a Paid Subscription using a Code, your
          subscription will automatically terminate at the end of the period
          stated in the Code, or when there is an insufficient pre-paid balance
          to pay for the Ticet Edu Service.
        </p>

        <h6 className="small mb-2">
          Effect of Content Removal on Subscriptions
        </h6>

        <p className="small mb-2">
          If any content posted by you is found to violate our policies or
          applicable laws, Ticet Edu reserves the right to delete or remove such
          content without prior notice. In such cases, no refunds will be
          provided for any active subscriptions or fees paid by you, even if the
          deletion of content impacts your use of the Platform. By subscribing
          to our services, you acknowledge and agree that compliance with our
          terms and legal requirements is a condition of continued access to the
          Platform, and any breach may result in content removal without
          compensation.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">14. Company Accounts</h6>

        <p className="small mb-2">
          If you establish a Ticet Edu account on behalf of a company,
          organization, entity, or brand (a &quot;Company,&quot; and such
          account a &quot;Company Account&quot;), the terms &quot;you&quot; and
          &quot;your,&quot; as used throughout these Terms (including other
          Ticet Edu terms and conditions incorporated by reference herein),
          apply to both you and the Company.
        </p>

        <p className="small mb-2">
          If you create a Company Account, you represent and warrant that you
          are authorized to grant all permissions and licenses provided in these
          Terms (including any other applicable Ticet Edu terms and conditions)
          and to bind the Company to these Terms.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">
          15. Account Suspension and Termination
        </h6>

        <p className="small mb-2">
          You agree that Ticet Edu, at its sole discretion, may terminate any
          account you have with Ticet Edu or your use of the Services and remove
          any part of your account, subscription, user profile, and content at
          any time, with or without notice. Termination may occur for any
          reason, including but not limited to extended inactivity on your
          account, abuse of the platform or technical services, failure to
          adhere to our Platform Integrity guidelines set out in these Terms, or
          abuse of our support services. Ticet Edu may also discontinue
          providing access to the Services, or any part thereof, at any time,
          with or without notice.
        </p>

        <p className="small mb-2">
          You agree that termination of your access to the Services or any
          account and/or subscription you have, or any part thereof, may occur
          with or without notice, and you agree that Ticet Edu will not be
          liable to you or any third party for such termination. Any suspected
          fraudulent, abusive, or illegal activity may be referred to law
          enforcement authorities. These remedies are in addition to any other
          legal or equitable remedies available to Ticet Edu.
        </p>

        <p className="small mb-2">
          If there are concerns about potential violations of our Terms or
          policies, Ticet Edu may suspend your account while we conduct an
          investigation. During this period, you will not have access to the
          Platform, and any ongoing activities will be halted. The suspension
          allows us to review the situation thoroughly and determine if there
          are grounds for terminating the account. If the investigation
          concludes that a violation has occurred, further actions, including
          termination of the account, may be taken. You will be notified of the
          outcome and any subsequent steps including appeals.
        </p>

        <p className="small mb-2">
          You can also cancel or delete Your Account and/or Subscription through
          Your Account settings or by emailing a cancellation or deletion
          request to Our Customer Support team at support@ ticetedu.com.{" "}
        </p>

        <p className="small mb-2">
          These Terms shall continue to apply until terminated by either you or
          Ticet Edu.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">16. Indemnification</h6>

        <p className="small mb-2">
          You agree to indemnify, save, and hold Us, any of our affiliated
          companies, contractors, employees, agents, licensors, and partners
          harmless from any claims, losses, damages, or liabilities, including
          legal fees and expenses, arising out of Your use or misuse of the
          Platform, any violation by You of these Terms, or any breach of the
          representations, warranties, and covenants made by You herein. We
          reserve the right, at Your expense, to assume the exclusive defense
          and control of any matter for which You are required to indemnify Us,
          and You agree to cooperate with Our defense of these claims. We will
          use reasonable efforts to notify You of any such claim, action, or
          proceeding upon becoming aware of it.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">17. Limitation of Liability</h6>

        <p className="small mb-2">
          To the fullest extent permitted by law, Ticet Edu, its affiliates, and
          their respective directors, officers, employees, and agents shall not
          be liable for any indirect, incidental, special, consequential, or
          punitive damages or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Platform; (ii) any conduct or content
          of any third party on the platform, including without limitation, any
          defamatory, offensive, or illegal conduct of other users or third
          parties; (iii) any content obtained from the Platform; and (iv)
          unauthorized access, use, or alteration of your transmissions or
          content.
        </p>

        <p className="small mb-2">
          In no event shall Ticet Edu's aggregate liability for all claims
          related to the Content exceed the amount you paid Ticet Edu, if any,
          in the past twelve (12) months for access to the paid Services.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">18. Governing Law</h6>

        <p className="small mb-2">
          These Terms shall be governed and construed in accordance with the
          Laws of Kenya.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">19. Dispute Resolution</h6>

        <p className="small mb-2">
          In the event of any dispute, controversy, or claim arising out of or
          relating to the conclusion, interpretation, or performance of this
          Agreement or breach, termination, or invalidity thereof, the Parties
          shall consult and negotiate with each other and, recognizing their
          mutual interests, attempt to reach a solution satisfactory to both
          Parties.
        </p>

        <p className="small mb-2">
          In the event that the good faith negotiations do not result in an
          amicable resolution of the dispute within fourteen (14) days of the
          dispute arising, the dispute shall be referred to arbitration.
        </p>

        <p className="small mb-2">
          The dispute shall be referred to a single arbitrator to be appointed
          by agreement between the Parties or in default of such agreement
          within fourteen (14) days of the notification of a dispute, upon the
          application of either Party, the arbitrator shall be appointed by the
          Chairperson for the time being of the Kenya Branch of the Chartered
          Institute of Arbitrators of the United Kingdom.
        </p>

        <p className="small mb-2">
          Such arbitration shall be conducted in Nairobi in accordance with the
          Rules of Arbitration of the said Institute and subject to and in
          accordance with the provisions of the Arbitration Act 1995.
        </p>

        <p className="small mb-2">
          The above notwithstanding, nothing in this Agreement shall prevent or
          delay a Party seeking urgent injunctive or interlocutory relief in a
          court having jurisdiction.
        </p>
      </div>

      <div className="mb-6">
        <h6 className="text-base mb-2">20. Entire Agreement</h6>

        <p className="small mb-2">
          Other than as stated in this section or as explicitly agreed upon in
          writing between you and Ticet Edu, these Terms constitute all the
          terms and conditions agreed upon between you and Us and supersede any
          prior agreements in relation to the subject matter of these Terms,
          whether written or oral.
        </p>

        <p className="small mb-2">
          Other terms and conditions governing use of the Ticet Edu Platform,
          including the Privacy Policy, are incorporated herein by reference.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
