import useAuth from './hooks/useAuth';

export const reverseDateString = str => {
  return str.toString().split("-").reverse().join("-");
};

export const customRedirect = redirectUrl => {
  window.location = redirectUrl;
};

export const getFullName = (firstName, lastName) => {
  if(firstName && lastName) return (firstName + ' ' + lastName);
  if(firstName && !lastName) return (firstName);
  if(lastName && !firstName) return (lastName);
  return '';
}

export const getImageData = (imageArray, id) => {
  let result = imageArray.find(ar => ar['id'] === id);
  return result ? result : false
}

export const backgroundImage = '/images/ticet_bg1.png';

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toSentenceCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getOptions = (arr, keyName) => {
  const result = []
  arr.map(item => (
      result.push(item[keyName])
  ));
  return result;
}

export const AuthGiver = () => {
  const {auth} = useAuth();
  return `sasa -- ${auth?.accessToken}`;
};

export const validateEmail = email => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const removeItemFromArray = (array, itemToRemove) => {
  return array.filter(item => item !== itemToRemove);
};

export const appendItemToArray = (array, item) => {
  return [...array, item];
}

export const isObjEmpty = obj => {
  if (obj == null) return true;
  return Object.keys(obj).length === 0;
}

export const findUserRole = (array) => {
  const roleItem = array?.find(item => typeof item === 'string' && item?.startsWith('ROLE_'));
  return roleItem || null;
}

export const obscurePhoneNumber = (phoneNumber) => {
  const lastFourChars = phoneNumber.slice(-4);
  const xString = 'X'.repeat(phoneNumber.length - 4);
  return xString + '-' + lastFourChars;
};

export const truncateText = (str, maxLength=50) => {
  return str.length <= maxLength ? str : str.substring(0, maxLength - 3) + "...";
};

export const getSpecificItemById = (itemArray, itemId) => {
  if (!itemArray || !itemId || itemArray.length === 0) return null;
  return itemArray?.find(item => String(item.id) === String(itemId)) || null;
};

export const isLibrary = item => typeof item === 'object' && item !== null && 'id' in item && 'curriculumLevel' in item;

export const cleanWorksheetName = (str) => str?.replace(/^\d+-/, '');

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const findFileId = (files, file) => {
  const matchingFile = files.find(
      item => item.fileName === file.fileName && item.fileUrl === file.assetUrl
  );
  return matchingFile ? matchingFile.id : null;
};