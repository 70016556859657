import {
  SET_PARENT_PERSONALINFO_FAILURE,
  SET_PARENT_PERSONALINFO_SUCCESS
} from './constants'

const initialState = {
  payload: [],
  error: null
}

export default function setParentPersonalInfo(state=initialState, action) {
  const { type, payload, error } = action

  switch(type) {
    case SET_PARENT_PERSONALINFO_SUCCESS:
      return {
        ...state, payload
      };
    case SET_PARENT_PERSONALINFO_FAILURE:
      return {
        ...state, error
      };
    default:
      return state;
  }
}
