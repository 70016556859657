import React from "react";

const WorkspaceClusterHeader = () => {
  return (
    <div className="mt-16 lg:mt-20 ml-0">
      <div
        className={` pt-8 flex h-full px-4 md:px-6 lg:px-8 text-center justify-center bg-primary-950 bg-cover bg-center animate-pulse`}
        style={{
          backgroundImage: `url("/images/my-workspace_bg.png")`,
          backgroundRepeat: "repeat-x",
          animation: "slide 150s linear infinite",
        }}
      >
        <div className="flex flex-col items-center max-w-8xl">
          <div className="flex flex-col items-center gap-4 max-w-5xl">
            <title className="text-app-colors-heading-d dark:text-app-colors-heading-d mb-2 font-medium">
              Ticet EDU Workspace
            </title>
            <p className=" text-app-colors-heading-d dark:text-app-colors-heading-d mb-2">
              Welcome to the Ticet EDU Workspace—your creative hub! Here, you
              can upload and create diverse content, manage your library, access
              drafts, view published materials, and collaborate with others to
              develop and curate content. You’ll also have the ability to
              monitor any content flagged for terms of use violations. Choose
              from a variety of content types to create, including worksheets,
              courses, flashcards, quizzes, exercises, and lesson plans/schemes
              of work.
            </p>
          </div>
          <img
            src="/images/statistics-bottom.png"
            alt=""
            className="flex w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceClusterHeader;
