import axios from 'axios';
import { CLIENT_API_URL } from '../../../../utils/constants';

import {
  CREATE_PARENT_FAILURE,
  CREATE_PARENT_LOADING,
  CREATE_PARENT_SUCCESS
} from './constants'
import {getJwtTokenCookie} from '../../../../utils/cookieHelper';

export const createParentLoading = () => {
  return {
    type: CREATE_PARENT_LOADING
  }
}

export const createParentSuccess = message => {
  return {
    type: CREATE_PARENT_SUCCESS,
    message
  }
}

export const createParentFailure = error => {
  return {
    type: CREATE_PARENT_FAILURE,
    error
  }
}

export const createParent = ({ countryIso2Code, dateOfBirth, parentUsername, gender, phoneNumber, schoolIds, nationalIdNumber, nationalIdUrl }) => async dispatch => {
  const parentDetails = {
    countryIso2Code,
    dateOfBirth,
    parentUsername,
    gender,
    phoneNumber,
    schoolIds,
    nationalIdNumber,
    nationalIdUrl
  }

  dispatch(createParentLoading())
  axios.post(`${CLIENT_API_URL}/accounts/parents`, parentDetails, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
      .then(res => {
        dispatch(createParentSuccess(['Parent registered successfully', res.data]));
      })
      .catch(error => {
        dispatch(createParentFailure({
          message: 'An error occurred while registering parent'
        }))
      })
}