import React from "react";
import { toSentenceCase } from "../../utils/common";

const WorkspaceLibraryCard = ({
  fullName,
  noOfUploads,
  libraryType,
  handleConsolidatedLibraryRoutePage,
}) => {
  return (
    <div
      className="group surface surface-hover min-w-72 rounded cursor-pointer"
      style={{ aspectRatio: "16/9" }}
      onClick={handleConsolidatedLibraryRoutePage}
    >
      <div
        className="py-4 bg-no-repeat bg-cover bg-center h-1/2 w-full rounded-t"
        style={{ backgroundImage: "url(/images/card-bg.jpeg)" }}
      />
      <div className="p-4">
        <h6 className="text-base text-hover mb-6 overflow-hidden overflow-ellipsis whitespace-nowrap line-clamp-1">
          {fullName}
        </h6>
        <p className="small mb-1 text-sm uppercase">
          {" "}
          <span className="font-medium">
            Uploads:{" "}
            <span className="text-secondary-blue-500">{noOfUploads}</span>{" "}
          </span>{" "}
        </p>
        <p className="small text-sm uppercase">
          {" "}
          <span className="font-medium">
            Library Type:{" "}
            <span className={"text-secondary-green-500"}>
              {toSentenceCase(libraryType)}
            </span>{" "}
          </span>{" "}
        </p>
      </div>
    </div>
  );
};

export default WorkspaceLibraryCard;
