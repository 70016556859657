import {
  SET_ACCOUNT_TYPE_FAILURE,
  SET_ACCOUNT_TYPE_SUCCESS
} from './constants'

const initialState = {
  payload: 'student',
  error: null
}

export default function setAccountType(state=initialState, action) {
  const { type, payload, error } = action

  switch(type) {
    case SET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state, payload
      };
    case SET_ACCOUNT_TYPE_FAILURE:
      return {
        ...state, error
      };
    default:
      return state;
  }
}