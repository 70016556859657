import React, {useState} from 'react';
import styled from 'styled-components'
import {useDispatch} from 'react-redux';
import {uploadImageAction} from '../../commonData/imageUpload/action';

const BorderImageInput = ({ id, labelText, borderText }) => {

  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);

  return (


  <div>
    <span className="line block m-1">
      <div className={`text-gray-400 border border-gray-400 focus-within:border-i-purple-500 focus:text-i-purple-500 rounded relative ${(borderText.includes('*')) ? 'border-i-orange-500 focus-within:border-i-orange-500' : ''}`}>
        <span className={`ml-2 bg-i-purple-50 relative py-0 px-1 bottom-4 text-xs ${focused ? 'text-i-purple-500' : ''} ${(borderText.includes('*')) ? 'text-i-orange-500' : ''}`}>
          {borderText}
        </span>
        <div>
          <ImageButtonInput className="file-input bg-transparent text-black py-1 px-2 mt-2 mr-0 text-xs">
            <input
              type="file"
              id={id}
              className="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => (dispatch(uploadImageAction(e.target.files, id)))}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <label htmlFor={id}>{labelText}</label>
          </ImageButtonInput>
        </div>
      </div>
    </span>
  </div>
  );
}

const ImageButtonInput = styled.div`
  position: relative;
  .file {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    cursor: pointer;
  }
`;

export default BorderImageInput