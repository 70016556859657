import React, { useEffect, useState } from 'react';
import { obscurePhoneNumber } from '../../../../../utils/common';

const PaymentMethod = () => {

  const [paymentPageInfo, setPaymentPageInfo] = useState({});
  const [selectedPaymentOptionIndex, setSelectedPaymentOptionIndex] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('M-Pesa');
  const [selectedSavedNumberIndex, setSelectedSavedNumberIndex] = useState(null);
  const [selectedSavedNumber, setSelectedSavedNumber] = useState(null);
  const [selectedPayCycleIndex, setSelectedPayCycleIndex] = useState(0);
  const [selectedPayCycle, setSelectedPayCycle] = useState('4 months');

  // const handleChange = e => {
  //   setPaymentPageInfo({ ...paymentPageInfo, [e.target.name]: e.target.value });
  // }

  // const [switchToggle, setSwitchToggle] = useState(false);

  // const handleToggle = () => {
  //   setSwitchToggle(!switchToggle);
  // };

  const paymentOptions = ['M-Pesa', 'Ticet Tokens'];
  const savedNumbers = ['0712345678', '0787654321'];
  const payCycleOptions = ['4 months', '1 year'];

  const handlePaymentOptionChange = (index, e) => {
    setSelectedPaymentOptionIndex(index);
    setSelectedPaymentOption(e.target.value);
  }

  const handleSavedNumberChange = (index, e) => {
    setSelectedSavedNumberIndex(index);
    setSelectedSavedNumber(e.target.value);
  }
  
  const handlePayCycleOptionChange = (index, e) => {
    setSelectedPayCycleIndex(index);
    setSelectedPayCycle(e.target.value);
  }

  useEffect(() => {
    console.log('Selected payment--', selectedPaymentOption)
  }, [selectedPaymentOption])

  useEffect(() => {
    console.log('Selected number--', selectedSavedNumber)
  }, [selectedSavedNumber])

  useEffect(() => {
    console.log('Selected pay cycle--', selectedPayCycle)
  }, [selectedPayCycle])

  return (
    <div>
      <div className=" surface rounded-md w-full">
        <div className="pt-12 pb-6 lg:pt-14 px-6 border-b-2 border-app-colors-border dark:border-app-colors-border-d">
          <h6>Payment Method</h6>
        </div>
        <div className="px-6 py-8 flex flex-col gap-4">
        <div className="p-3 text-xs flex gap-3">
          {paymentOptions.length > 0 ? (
            paymentOptions.map((option, index) => (
              <div className="mb-4" key={index + 1}>
                <div className="flex items-center mb-1" key={index + 1}>
                  <input
                    id={`default-radio-${index + 1}`}
                    type="radio"
                    value={option}
                    className="w-4 h-4 text-i-purple-500 bg-white border-gray-300 focus:ring-i-purple-500 focus:ring-1"
                    checked={selectedPaymentOptionIndex === index}
                    onChange={(e) => handlePaymentOptionChange(index, e)}
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-xs font-medium text-gray-700"
                  >
                    {option}
                  </label>
                </div>
              </div>
            ))
          ) : (
            <div>There are no payment options</div>
          )}
        </div>

        <div className={"p-3 text-xs text-gray-700"}>
          <div className="flex justify-between pr-2">
            <div className="font-bold">Saved Numbers</div>
            <div className="flex gap-1 items-center text-i-purple-500 text-sm cursor-pointer hover:underline">
              <span className="text-lg">+</span>
              <div>Add New</div>
            </div>
          </div>

          <div className="mt-3">
            {savedNumbers.length > 0 ? (
              savedNumbers.map((number, index) => (
                <div className="mb-4" key={index + 1}>
                  <div className="flex items-center mb-1" key={index + 1}>
                    <input
                      id={`default-radio-${index + 1}`}
                      type="radio"
                      value={number}
                      className="w-4 h-4 text-i-purple-500 bg-white border-gray-300 focus:ring-i-purple-500 focus:ring-1"
                      checked={selectedSavedNumberIndex === index}
                      onChange={(e) => handleSavedNumberChange(index, e)}
                    />
                    <label
                      htmlFor="default-radio-1"
                      className="ml-2 text-xs font-medium text-gray-700 flex justify-between items-center w-full pr-2"
                    >
                      <div>M-Pesa {obscurePhoneNumber(number)}</div>
                      <div className="flex gap-2">
                        <span>
                          <img src="/images/mpesa-icon.png" alt="mpesa" />
                        </span>
                        <span></span>
                      </div>
                    </label>
                  </div>
                </div>
              ))
            ) : (
              <div>There are no saved numbers</div>
            )}
          </div>
        </div>

        
        <div className={"grid sm:grid-cols-2 gap-3 p-3"}>
          <div className="text-xs">
            <div className="grid grid-cols-3">
              <div />
              <div className="col-span-2 gap-3 grid grid-cols-2">
                {payCycleOptions.length > 0 ? (
                  payCycleOptions.map((option, index) => (
                    <div className="mb-4" key={index + 1}>
                      <div className="flex items-center mb-1" key={index + 1}>
                        <input
                          id={`default-radio-${index + 1}`}
                          type="radio"
                          value={option}
                          className="w-4 h-4 text-i-purple-500 bg-white border-gray-300 focus:ring-i-purple-500 focus:ring-1"
                          checked={selectedPayCycleIndex === index}
                          onChange={(e) => handlePayCycleOptionChange(index, e)}
                        />
                        <label
                          htmlFor="default-radio-1"
                          className="ml-2 text-xs font-medium text-gray-700"
                        >
                          {option}
                        </label>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>There are no pay cycle options</div>
                )}
              </div>
            </div>
            <div className="text-gray-700">
              <div className="border-t border-b border-gray-200">
                <div className="grid grid-cols-3 gap-3 py-1">
                  <div>Subtotal</div>
                  <div>KES 500.00</div>
                  <div>KES 1200.00</div>
                </div>
                <div className="grid grid-cols-3 gap-3 py-1">
                  <div>VAT</div>
                  <div>KES 123.00</div>
                  <div>KES 243.00</div>
                </div>
                <div className="grid grid-cols-3 gap-3 py-1 font-semibold">
                  <div>Total</div>
                  <div>KES 623.00</div>
                  <div>KES 1443.00</div>
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
        <div className="p-3">
          <button className="bg-i-purple-500 text-white rounded-full px-6 py-2 text-xs">
            Proceed
          </button>
        </div>
        <div className="p-3 text-center text-xs text-gray-500">
          <div>
            <span className="font-semibold">Please note: </span>TICET will never
            ask you for your password, PIN, CVV or card details over he phonr or
            via email.
          </div>
          <div>
            Need help?{" "}
            <div className="font-semibold text-i-purple-500">Contact Us.</div>
          </div>
        </div></div>
      </div>
    </div>
  );
};

export default PaymentMethod;