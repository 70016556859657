import axios from 'axios';
import { CLIENT_API_URL } from '../../utils/constants';
import {
  GET_CATEGORY_LOADING,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS
} from './constants';
import {getJwtTokenCookie} from '../../utils/cookieHelper';

export const getCategoriesLoading = () => ({
  type: GET_CATEGORY_LOADING
});

export const getCategoriesSuccess = payload => ({
  type: GET_CATEGORY_SUCCESS,
  payload
});

export const getCategoriesFailure = error => ({
  type: GET_CATEGORY_FAILURE,
  error
});

export const getCategoriesAction = (curriculumId) => async dispatch => {
  dispatch(getCategoriesLoading());

  const curriculumIdArray = [];
  curriculumIdArray.push(curriculumId);
  axios.get(`${CLIENT_API_URL}/curricula/levels?curriculaIds=${curriculumIdArray}`, {
    headers: {
      'Authorization': `Bearer ${await getJwtTokenCookie()}`
    }
  })
  .then(res => {
    dispatch(getCategoriesSuccess(res.data.data));
  })
  .catch(error => {
    dispatch(getCategoriesFailure({
      message: 'An error occurred while fetching Category'
    }))
  })
}