import {
  GET_WORKSHEETS_LOADING,
  GET_WORKSHEETS_FAILURE,
  GET_WORKSHEETS_SUCCESS,
  GET_WORKSHEETS_CLEAR
} from './constants';

const initialState = {
  loading: false,
  payload: [],
  error: null
};

export default function getWorksheets(state = initialState, action) {
  const { type, payload, error } = action;

  switch(type) {
    case GET_WORKSHEETS_LOADING:
      return { ...state, loading: true };
    case GET_WORKSHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload
      };
    case GET_WORKSHEETS_FAILURE:
      return {
        ...state,
        loading: false,
        error
      };
      case GET_WORKSHEETS_CLEAR:
        return {
          ...state,
          loading: false,
          payload: []
        };
    default:
      return state;
  }
}