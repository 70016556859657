import React, {useEffect} from 'react';

const PageLayout = ({ children }) => {

  const dark = localStorage.getItem("color-theme") === "dark" ||
      (!("color-theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [dark]);
  return (
    <div className="mx-auto w-full">
      {children}
    </div>
  );
};

export default PageLayout;
