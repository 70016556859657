import React from 'react';
import {
  Routes, Route
} from 'react-router-dom';
import ROUTES from './utils/routes';
import '../src/styles/tailwind.css'
import Home from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import AccountType from './pages/signup/selectAccount';
import StudentPersonalInfo from './pages/signup/student/personalInfo/index'
import StudentSelectSchool from './pages/signup/student/selectSchool/index'
import StudentAuthentication from './pages/signup/student/authentication/index'
import ParentPersonalInfo from './pages/signup/parent/personalInfo/index'
import ParentSelectSchool from './pages/signup/parent/selectSchool/index'
import ParentAuthentication from './pages/signup/parent/authentication/index'
import TeacherPersonalInfo from './pages/signup/teacher/personalInfo/index'
import TeacherSelectSchool from './pages/signup/teacher/selectSchool/index'
import TeacherAuthentication from './pages/signup/teacher/authentication/index'
import SchoolDetails from './pages/signup/school/schoolDetails'
import SchoolAuthentication from './pages/signup/school/authentication/index'
import Register from './pages/signup/register';
import AccountSetUpConnections from './pages/AccountSetup/connections';
import AccountSetUpSubjects from './pages/AccountSetup/subjects';
import AccountSetUpLibraries from './pages/AccountSetup/libraries';
import Layout from './Layout';
import UserSpaceTimeline from './pages/userSpace/timeline';
import UserSpaceLibrary from './pages/userSpace/libraryCluster';
import MyLibrarySpace from './pages/userSpace/myLibrarySpace';
import PersonalLibrary from './pages/userSpace/personalLibrary';
import PrivateRoutes from './utils/auth/PrivateRoutes';
import UpdateLibraryWorksheets from './pages/userSpace/updateLibraryWorksheets';
import LibraryWorksheets from './pages/userSpace/libraryWorksheets';
import ViewLibraryWorksheet from './pages/userSpace/viewLibraryWorksheet';
import LibrarySubjects from './pages/userSpace/librarySubjects';
import AccountSettings from './pages/Account/Settings';
import MyWorkSpace from './pages/userSpace/myWorkSpace';
import Videos from './pages/Community/Videos';
import SingleVideoPage from './pages/Community/Videos/SingleVideoPage';
import TestScroll from './pages/Development/testScroll';
import WorkSpaceLibraries from './pages/userSpace/myWorkSpace/workspaceLibraries';
import Announcements from './pages/userSpace/announcements';


function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route exact path={ROUTES.home} element={<Home/>} />
        <Route exact path={ROUTES.register} element={<Register/>} />
        <Route exact path={ROUTES.accountType} element={<AccountType/>} />
        <Route element={<PrivateRoutes />}>
          <Route exact path={ROUTES.studentSignUpPersonalInfo} element={<StudentPersonalInfo/>} />
          <Route exact path={ROUTES.studentSignUpSelectSchool} element={<StudentSelectSchool/>} />
          <Route exact path={ROUTES.studentSignUpAuthentication} element={<StudentAuthentication/>} />
          <Route exact path={ROUTES.parentSignUpPersonalInfo} element={<ParentPersonalInfo/>} />
          <Route exact path={ROUTES.parentSignUpSelectSchool} element={<ParentSelectSchool/>} />
          <Route exact path={ROUTES.parentSignUpAuthentication} element={<ParentAuthentication/>} />
          <Route exact path={ROUTES.teacherSignUpPersonalInfo} element={<TeacherPersonalInfo/>} />
          <Route exact path={ROUTES.teacherSignUpSelectSchool} element={<TeacherSelectSchool/>} />
          <Route exact path={ROUTES.teacherSignUpAuthentication} element={<TeacherAuthentication/>} />
          <Route exact path={ROUTES.schoolSignUpSchoolDetails} element={<SchoolDetails/>} />
          <Route exact path={ROUTES.schoolSignUpAuthentication} element={<SchoolAuthentication/>} />
          <Route exact path={ROUTES.accountSetUpSubjects} element={<AccountSetUpSubjects/>} />
          <Route exact path={ROUTES.accountSetUpConnections} element={<AccountSetUpConnections/>} />
          <Route exact path={ROUTES.accountSetUpLibraries} element={<AccountSetUpLibraries/>} />
          <Route exact path={ROUTES.userSpaceTimeline} element={<UserSpaceTimeline/>} />
          <Route exact path={ROUTES.userSpaceLibrary} element={<UserSpaceLibrary/>} />
          <Route exact path={ROUTES.myLibrarySpace} element={<MyLibrarySpace/>} />
          <Route exact path={ROUTES.personalLibrary} element={<PersonalLibrary/>} />
          <Route exact path={ROUTES.updateLibraryWorksheets} element={<UpdateLibraryWorksheets/>} />
          <Route exact path={ROUTES.myWorkspace} element={<MyWorkSpace/>} />
          <Route exact path={ROUTES.communityVideos} element={<Videos/>} />
          <Route exact path={ROUTES.singleVideoPage} element={<SingleVideoPage/>} />
          <Route exact path={ROUTES.developmentTestScroll} element={<TestScroll/>} />
          <Route exact path={ROUTES.libraryWorksheets} element={<LibraryWorksheets/>} />
          <Route exact path={ROUTES.viewLibraryWorksheet} element={<ViewLibraryWorksheet/>} />
          <Route exact path={ROUTES.userSpaceLibrarySubjects} element={<LibrarySubjects/>} />
          <Route exact path={ROUTES.accountSettings} element={<AccountSettings/>} />
          <Route exact path={ROUTES.workspaceLibraries} element={<WorkSpaceLibraries/>} />
          <Route exact path={ROUTES.userSpaceAnnouncements} element={<Announcements/>} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
